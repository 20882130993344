import React from 'react';

import { initialState } from './constants';

import { InitialState } from './types';

const FormContext = React.createContext({
  state: initialState,
  dispatch: (args: Partial<InitialState>) => {
    console.log(args);
  },
});

export default FormContext;
