import React, { useCallback } from 'react';
import { useUser as useUserStore } from 'src/store';
import MinimizedTask from 'src/widgets/MinimizedTaskList/MinimizedTask';

import * as Styled from './styles';
import { useUser } from 'src/hooks/useUser';
import { useTask } from 'src/hooks/useTask';

const MinimizedTaskList = () => {
  const user = useUserStore();
  const { onUpdateUserMinimizedTasks } = useUser();
  const { onTaskOpen } = useTask();

  const { minimizedTasks = [] } = user;

  const onClose = useCallback(
    taskId => {
      onUpdateUserMinimizedTasks({
        removeMinimizedTaskId: taskId,
      });
    },
    [onUpdateUserMinimizedTasks],
  );

  if (!minimizedTasks?.length) return null;

  return (
    <Styled.Container>
      {[...minimizedTasks]
        .reverse()
        ?.map(task => (
          <MinimizedTask
            key={task._id}
            task={task}
            onClose={onClose}
            onTaskClick={onTaskOpen}
          />
        ))}
    </Styled.Container>
  );
};

export default MinimizedTaskList;
