import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  padding: 6px 10px;
  border-radius: 4px;
  width: 100%;

  input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 16px;
    background: transparent;
    padding: 4px;
    color: ${({ theme }) => theme.text.primary};
  }
`;
