import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 20px;
`;

export const ModalHeader = styled.div`
  h2 {
    margin: 0 0 20px 0;
  }
`;
