import React from 'react';
import { OptionProps } from 'react-select';

import { Flex } from 'src/components/design-system';

import Option from '../Option/Option';

import * as Styled from './styles';

const StatusesOption = ({
  data,
  innerRef,
  innerProps,
  isFocused,
  isSelected,
}: OptionProps) => {
  // @ts-ignore
  const { label, color } = data;

  return (
    <Option
      ref={innerRef}
      isFocused={isFocused}
      isSelected={isSelected}
      {...innerProps}
    >
      <Flex $padding={[8, 5]} $alignItems="center" $gap={10}>
        <Styled.StatusesColor color={color} />
        <div>{label}</div>
      </Flex>
    </Option>
  );
};

export default StatusesOption;
