import React from 'react';
import TaskModal from './TaskModal';
import TaskModalSkeleton from './TaskModalSkeleton';
import { useGetActionQuery } from 'src/generated';

const TaskModalLoader = ({ taskId, onClose }) => {
  const { data, loading, error } = useGetActionQuery({
    variables: {
      id: taskId,
    },
  });

  if (loading) return <TaskModalSkeleton />;

  if (error || !data?.action) return null;

  return <TaskModal action={data.action} onClose={onClose} />;
};

export default TaskModalLoader;
