import styled, { keyframes } from 'styled-components';

const lineAnim = keyframes`
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
`;

type LineLoaderProps = {
  color?: string;
};

export const LineLoader = styled.div<LineLoaderProps>`
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;
  &:before {
    content: '';
    position: absolute;
    left: -50%;
    height: 4px;
    width: 40%;
    background-color: ${({ color, theme }) => color || theme.color.orange500};
    animation: ${lineAnim} 1s linear infinite;
    border-radius: 20px;
  }
`;
