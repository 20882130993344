import { Flex } from 'src/components/design-system';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

type NotificationProps = {
  isRead?: boolean;
};

const pulse = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.4;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

export const Notification = styled(Flex)<NotificationProps>`
  padding: 20px 30px 20px 20px;
  position: relative;
  cursor: pointer;
  background: ${({ theme, isRead }) =>
    isRead ? theme.background.secondary : 'rgba(43, 116, 217, 0.1)'};
  &:hover {
    background: ${({ theme, isRead }) =>
      isRead ? theme.background.secondary : 'rgba(43, 116, 217, 0.1)'};
  }
  &:before {
    content: '';
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    height: 1px;
    background: ${({ theme }) => theme.border.color.primary};
  }
`;

export const Title = styled.h4`
  margin: 0;
  font-weight: 600;
`;

export const UnreadDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.success};
  animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
  transform-origin: center;
`;

export const NotificationTime = styled.div`
  color: ${({ theme }) => theme.color.grey500};
  font-size: 12px;
`;
