import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  Box,
  Flex,
  Button,
  FormItem,
  useNotification,
} from 'src/components/design-system';

import { useUserWorkspace } from 'src/store';

import { useInviteProjectMemberMutation } from 'src/generated';

type InviteFromProps = {
  email: string;
};

const InviteFrom = ({ handleClose }) => {
  const workspaceId = useUserWorkspace();
  const notification = useNotification();
  const { id: projectId } = useParams() as any;
  const methods = useForm<InviteFromProps>();
  const { handleSubmit, setError } = methods;

  const [inviteProjectMember, { loading }] = useInviteProjectMemberMutation({
    onError: ({ graphQLErrors }) => {
      console.log(graphQLErrors, 'error inviteProjectMember');
      graphQLErrors.forEach(({ extensions: { exception } }) => {
        // @ts-ignore
        const { response } = exception;

        setError(response.type, {
          message: response.message,
        });
      });
    },
    onCompleted: () => {
      handleClose();
      notification.show({
        title: 'User successfully invited',
        message: 'Please wait for the user to accept the invitation',
        variant: 'success',
      });
    },
  });

  const onSubmit = ({ email }) => {
    inviteProjectMember({
      variables: { workspaceId, projectId, email },
    });
  };

  return (
    <Box $padding={[20, 0]}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex $gap={10} $alignItems="flex-start">
            <FormItem name="email">
              <input name="email" type="text" placeholder="Add teammates" />
            </FormItem>

            <Button loading={loading}>{'Invite'}</Button>
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
};

export default InviteFrom;
