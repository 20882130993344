import React from 'react';

import { Flex } from 'src/components/design-system';

import FormBuilder from './FormBuilder/FormBuilder';
import FormElements from './FormElements/FormElements';
import FormFieldSettings from './FormFieldSettings/FormFieldSettings';

import * as Styled from '../styles';

const FormDesign = () => {
  return (
    <Flex height="100%">
      <FormElements />
      <Flex $flex="1">
        <Styled.Content>
          <FormBuilder />
        </Styled.Content>
      </Flex>
      <Styled.FieldSetting>
        <FormFieldSettings />
      </Styled.FieldSetting>
    </Flex>
  );
};

export default FormDesign;
