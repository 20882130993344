import React, { useEffect, useRef, useState } from 'react';
import { useSlate, useFocused } from 'slate-react';

import { Portal, Icon } from 'src/components/design-system';

import Button from '../../Button/Button';
import Embed from '../Elements/Embed/Embed';
import LinkButton from '../Elements/Link/LinkButton';
import { activeMark, isBlockActive } from '../utils/SlateUtilityFunctions';
import { insertLink } from '../utils/link';
import useTable from '../utils/useTable';
import BlockButton from './BlockButton';
import ColorPicker from './Color Picker/ColorPicker';
import Dropdown from './Dropdown';
import MarkButton from './MarkButton';
import InTable from './Table/InTable';
import Table from './Table/Table';
// toolbar buttons
import UploadImage from './UploadImage/UploadImage';
import toolbarMenu from './toolbarMenu';
import { Editor, Range } from 'slate';

import { ToolbarBlock, Container, UrlForm } from './styles';

const Toolbar = () => {
  const editor = useSlate();
  const inFocus = useFocused();
  const isTable = useTable(editor);

  const [currentToolbarMenu, setCurrentToolbarMenu] = useState(toolbarMenu);
  const [isOpenUrl, setIsOpenUrl] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const urlRef = useRef<HTMLDivElement>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);

  const domSelection = window.getSelection();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const domRange = domSelection!.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    el.style.opacity = '1';
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left - 25}px`;
    // align center
    // el.style.left = `${
    //   rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    // }px`;
  });

  useEffect(() => {
    const el = urlRef.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (!isOpenUrl) {
      return;
    }

    if (!selection) {
      el.removeAttribute('style');
      return;
    }

    const domRange = domSelection!.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    el.style.opacity = '1';
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    // el.style.left = `${
    //   rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    // }px`;
    el.style.left = `${rect.left - 25}px`;
  }, [urlRef, isOpenUrl, editor, domSelection]);

  useEffect(() => {
    let filteredGroups = [...toolbarMenu];
    if (isTable) {
      filteredGroups = currentToolbarMenu.map(grp =>
        grp.filter(element => element.type !== 'block'),
      );
      filteredGroups = filteredGroups.filter(elem => elem.length);
    }
    setCurrentToolbarMenu(filteredGroups);
  }, [isTable]);

  useEffect(() => {
    if (isOpenUrl) {
      urlInputRef.current?.focus();
    }
  }, [isOpenUrl]);

  const handleToggleInsertLink = e => {
    e.preventDefault();
    setIsOpenUrl(!isOpenUrl);
  };

  const handleInsertLink = e => {
    e.preventDefault();
    insertLink(editor, e.target.url.value);
    setIsOpenUrl(false);
  };

  return (
    <>
      <Portal>
        <Container
          ref={ref}
          onMouseDown={e => {
            // prevent toolbar from taking focus away from editor
            e.preventDefault();
          }}
        >
          <ToolbarBlock>
            {toolbarMenu.map((group, index) => (
              <span key={index} className="toolbar-grp">
                {group.map(element => {
                  switch (element.type) {
                    case 'block':
                      return <BlockButton key={element.id} {...element} />;
                    case 'mark':
                      return <MarkButton key={element.id} {...element} />;
                    case 'dropdown':
                      return <Dropdown key={element.id} {...element} />;
                    case 'link':
                      return (
                        <LinkButton
                          key={element.id}
                          $active={isBlockActive(editor, 'link')}
                          editor={editor}
                          handleToggleInsertLink={handleToggleInsertLink}
                        />
                      );
                    case 'embed':
                      return (
                        <Embed
                          key={element.id}
                          format={element.format}
                          editor={editor}
                        />
                      );
                    case 'color-picker':
                      return (
                        <ColorPicker
                          key={element.id}
                          activeMark={activeMark}
                          format={element.format}
                          editor={editor}
                        />
                      );
                    case 'table':
                      return <Table key={element.id} editor={editor} />;
                    case 'inTable':
                      return (
                        isTable && <InTable key={element.id} editor={editor} />
                      );
                    case 'image':
                      return <UploadImage key={element.id} />;
                    default:
                      return null;
                  }
                })}
              </span>
            ))}
          </ToolbarBlock>
        </Container>
      </Portal>

      {/* custom modal for insert url */}
      {isOpenUrl && (
        <Portal>
          <Container ref={urlRef}>
            <UrlForm onSubmit={handleInsertLink}>
              <input
                ref={urlInputRef}
                type="text"
                name="url"
                placeholder="Enter url"
                autoComplete="off"
              />
              <Button
                type="button"
                size="small"
                onClick={handleToggleInsertLink}
              >
                <Icon name="close" size={20} />
              </Button>
            </UrlForm>
          </Container>
        </Portal>
      )}
    </>
  );
};

export default Toolbar;
