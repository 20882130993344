import { useEffect, useCallback, useState, RefObject } from 'react';
import ReactDOM from 'react-dom';

type UseContextMenuProps = {
  triggerRef: RefObject<HTMLElement>;
  contextMenuRef: RefObject<HTMLElement>;
};

export const useContextMenu = ({
  triggerRef,
  contextMenuRef,
}: UseContextMenuProps) => {
  const [xPos, setXPos] = useState('0');
  const [yPos, setYPos] = useState('0');

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!triggerRef.current?.contains(event.target as HTMLElement))
        return setIsMenuVisible(false);

      const x = event.pageX;
      const y = event.pageY;

      // Open to other side if rest of space is too small
      // if (window.innerWidth - x < 250) x -= 250;
      // if (window.innerHeight - y < 300) y -= 250;

      setXPos(`${x}px`);
      setYPos(`${y}px`);

      setIsMenuVisible(true);

      return;
    },
    [triggerRef],
  );

  const handleClick = () => setIsMenuVisible(false);

  const handleClickOutside = useCallback(
    (event: any) => {
      // eslint-disable-next-line react/no-find-dom-node
      const domNode = ReactDOM.findDOMNode(contextMenuRef.current);

      if (!domNode || !domNode?.contains(event.target)) {
        // Check if you arent clicking outside on the button that opens this:
        // clickableRef && event.target.id === clickableRef.current.id;
        setIsMenuVisible(false);
      }
    },
    [contextMenuRef],
  );

  useEffect(() => {
    triggerRef.current?.addEventListener('click', handleClick);
    triggerRef.current?.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('contextmenu', handleClickOutside, true);
    return () => {
      triggerRef.current?.removeEventListener('click', handleClick);
      triggerRef.current?.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('contextmenu', handleClickOutside, true);
    };
  }, [handleClickOutside, handleContextMenu, triggerRef]);

  return { xPos, yPos, isMenuVisible, setIsMenuVisible };
};
