import React from 'react';

import { Flex, Avatar, serializeNodes } from 'src/components/design-system';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import { NotificationsQuery } from 'src/generated';

import * as Styled from './styles';

type ProjectActivityProps = {
  notification: NotificationsQuery['notifications'][0];
};

const safeParse = jsonString => {
  try {
    const json = JSON.parse(jsonString);
    return serializeNodes(json);
  } catch (error) {
    console.log('Error parsing JSON', error);
    return null;
  }
};

const ProjectActivity = ({ notification }: ProjectActivityProps) => {
  const { title, message, user, createdAt } = notification;

  return (
    <Styled.Container
      $alignItems="center"
      margin={[10, 0]}
      $padding={[0, 0, 10, 0]}
    >
      <Avatar user={user} />
      <Flex direction="column">
        <div dangerouslySetInnerHTML={{ __html: title }}></div>
        {message && (
          <div
            dangerouslySetInnerHTML={{
              __html: safeParse(message) ?? message,
            }}
          ></div>
        )}
        <Styled.Date>
          {DateHelpers.formatDate(createdAt, DateFormats.FullDate)}
        </Styled.Date>
      </Flex>
    </Styled.Container>
  );
};

export default ProjectActivity;
