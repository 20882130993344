import React, { useState, useRef, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { Input, Icon, Portal } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import SearchDropdown from '../SearchDropdown/SearchDropdown';
import { GET_TASKS } from 'src/graphql/actions';

import * as Styled from './styles';

const Search: React.FC = () => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // click away listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  const handleClick = e => {
    if (
      (dropdownRef.current && !dropdownRef.current.contains(e.target)) ||
      e.target.id === 'search-wrapper'
    ) {
      setVisible(false);
    }
  };

  const [getTasks, { data, loading }] = useLazyQuery(GET_TASKS, {
    fetchPolicy: 'network-only',
  });

  return (
    <Portal>
      <div ref={dropdownRef}>
        <Styled.Container id="search-wrapper">
          <Styled.InputWrapper>
            <Input
              type="text"
              placeholder="Type to search..."
              onFocus={() => {
                setVisible(true);
              }}
              onChange={e => {
                if (e.target.value.length <= 2) return;

                getTasks({
                  variables: {
                    workspaceId: '64cbf3f95fa190b7100c0e32',
                    search: e.target.value,
                  },
                });
              }}
              prefix={
                <Icon size={14} name="search" color={theme.color.grey600} />
              }
            />
          </Styled.InputWrapper>
        </Styled.Container>
        {visible && (
          <SearchDropdown tasks={data?.getTasks || []} loading={loading} />
        )}
      </div>
      {visible && <Styled.Overlay />}
    </Portal>
  );
};

export default Search;
