import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Flex } from 'src/components/design-system';

import AddAttachments from './AddAttachments';

import * as Styled from './styles';

const AttachmentsTitle = ({ taskId }) => {
  const { t } = useTranslation();

  return (
    <Flex $gap={10} $alignItems="center">
      <Icon name="comments" />
      <Styled.AddAttachmentsTitle>
        {t('task.detail.attachments')}
      </Styled.AddAttachmentsTitle>
      <AddAttachments id={taskId} />
    </Flex>
  );
};

export default AttachmentsTitle;
