import { Link } from 'react-router-dom';

import { Flex } from 'src/components/design-system';
import styled, { css } from 'styled-components';

export const Container = styled(Flex)`
  background: ${({ theme }) => theme.background.secondary};
`;

type MenuLinkProps = {
  $active?: boolean;
};

export const MenuLink = styled(Link)<MenuLinkProps>`
  cursor: pointer;
  padding: 16px 24px;
  &:hover {
    background: ${({ theme }) => theme.background.hover};
  }
  ${({ $active, theme }) =>
    $active &&
    css`
      background: ${theme.background.hover};
    `}
`;
