import { Box, LoadingIndicator, Icon } from 'src/components/design-system';

import { UnsplashPhoto } from 'src/common/types';

import * as Styled from './styles';

type UnsplashImageListProps = {
  isLoading: boolean;
  images: UnsplashPhoto[];
  onImageClick: (image: UnsplashPhoto) => void;
};

const UnsplashImageList = ({
  isLoading,
  images,
  onImageClick,
}: UnsplashImageListProps) => {
  if (isLoading) return <LoadingIndicator />;

  if (!images.length)
    return <Box>{'No images found or apps are limited requests per hour'}</Box>;

  return (
    <Styled.List>
      {images.map((image, index) => (
        <Styled.ImageBlock key={index}>
          <img src={image.urls.small} alt={image.alt_description} />
          <Styled.ImageDescriptionBlock
            $alignItems="center"
            $justifyContent="space-between"
            $gap={10}
          >
            <Styled.Description>{image.alt_description}</Styled.Description>
            <Styled.AttachButton
              icon={<Icon name="plus-square-fill" color="#5591ea" size={24} />}
              onClick={() => onImageClick(image)}
            />
          </Styled.ImageDescriptionBlock>
        </Styled.ImageBlock>
      ))}
    </Styled.List>
  );
};

export default UnsplashImageList;
