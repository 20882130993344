import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

type ItemProps = {
  selected?: boolean;
};

export const styles = css`
  color: ${p => p.theme.text.primaryHover};
  text-shadow: 0 0 0 1px #1e1f21;
  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -15px;
    right: -15px;
    height: 2px;
    background-color: ${p => p.theme.text.primaryHover};
    z-index: 1;
  }
`;

export const MenuLink = styled(Link)<ItemProps>`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  color: ${p => p.theme.header.text};
  position: relative;
  &:hover {
    ${styles}
  }
  svg {
    margin-right: 5px;
  }

  &:not(:first-child) {
    margin-left: 24px;
    @media (max-width: 1200px) {
      margin-left: 10px;
    }
  }

  ${props =>
    props.selected &&
    css`
      ${styles}
    `}
`;
