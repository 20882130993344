import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Modal } from 'src/components/design-system';

import TaskModalLoader from './TaskModalLoader';

const TaskModalContainer = () => {
  const locations = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(locations.search);
  const taskId = query.get('taskId');

  const [isOpen, setIsOpen] = useState(Boolean(taskId));

  useEffect(() => {
    if (taskId) setIsOpen(true);
    if (!taskId) setIsOpen(false);
  }, [taskId]);

  const handleCloseModal = () => {
    setIsOpen(false);
    query.delete('taskId');
    navigate({ search: query.toString() });
  };

  if (!isOpen) return null;

  return (
    <Modal
      width={1000}
      isOpen={isOpen}
      showCloseButton={false}
      showTopBorder={false}
      onRequestClose={handleCloseModal}
      style={{ content: { padding: 0 } }}
    >
      <TaskModalLoader taskId={taskId} onClose={handleCloseModal} />
    </Modal>
  );
};

export default TaskModalContainer;
