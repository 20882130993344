import styled from 'styled-components';

export const LinkContainer = styled.span`
  display: inline;
  position: relative;
`;

export const LinkPopup = styled.span`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  width: fit-content;
  z-index: 1;
  background-color: ${({ theme }) => theme.background.primary};
  border: 1px solid ${({ theme }) => theme.border.color.primary};
`;
