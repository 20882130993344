import { create } from 'zustand';

interface DrawerState {
  drawers: Record<string, boolean>; // Tracks the open/close state of each drawer by its unique ID
  openDrawer: (id: string) => void;
  closeDrawer: (id: string) => void;
  toggleDrawer: (id: string) => void;
  closeAllDrawers: () => void;
}

const useDrawerStoreBase = create<DrawerState>(set => ({
  drawers: {},

  openDrawer: id =>
    set(state => ({
      drawers: { ...state.drawers, [id]: true },
    })),

  closeDrawer: id =>
    set(state => ({
      drawers: { ...state.drawers, [id]: false },
    })),

  toggleDrawer: id =>
    set(state => ({
      drawers: { ...state.drawers, [id]: !state.drawers[id] },
    })),

  closeAllDrawers: () =>
    set(() => ({
      drawers: {},
    })),
}));

// Custom hook for accessing drawer-specific logic
export const useDrawerStore = (id: string) => {
  const drawers = useDrawerStoreBase(state => state.drawers);
  const openDrawer = useDrawerStoreBase(state => state.openDrawer);
  const closeDrawer = useDrawerStoreBase(state => state.closeDrawer);
  const toggleDrawer = useDrawerStoreBase(state => state.toggleDrawer);

  return {
    isOpen: drawers[id] || false, // Default to false if the drawer doesn't exist
    openDrawer: () => openDrawer(id),
    closeDrawer: () => closeDrawer(id),
    toggleDrawer: () => toggleDrawer(id),
  };
};

export const DRAWER_IDS = {
  TASK_ADVANCED_FIELDS: 'taskAdvancedFieldsDrawer',
  UNSPLASH: 'unsplash',
  NOTIFICATIONS: 'notifications',
  USER_SETTINGS: 'userSettings',
  TASK_DESCRIPTION_HISTORY: 'taskDescriptionHistory',
  PROJECT_FILTER: 'projectFilter',
  CUSTOM_FIELDS: 'customFields',
};
