import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EditableText, Flex, Icon } from 'src/components/design-system';

import { useStatuses } from 'src/hooks/useStatuses';

import { Container, AddColumnButton } from './styles';

const AddColumn = () => {
  const { t } = useTranslation();
  const { id: projectId } = useParams<{ id: string }>();

  const { onCreateStatus } = useStatuses();

  return (
    <Container>
      <EditableText
        placeholder="New section"
        customReadView={() => (
          <AddColumnButton $htmlType="text">
            <Flex $gap={10}>
              <Icon name="plus" />
              <span>{t('project.addColumn')}</span>
            </Flex>
          </AddColumnButton>
        )}
        onUpdate={async value => {
          if (!value) return;
          await onCreateStatus({ projectId, title: value });
        }}
      />
    </Container>
  );
};

export default AddColumn;
