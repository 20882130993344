import styled, { css } from 'styled-components';

type ContainerProps = {
  $inValid?: boolean;
};

export const Container = styled.div<ContainerProps>`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  input {
    padding: 8px 14px;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    height: auto;
    line-height: inherit;
    transition: all 0.3s;
    width: 100%;
    color: ${p => p.theme.text.primary};
    border: 1px solid ${p => p.theme.border.color.primary};
    background-color: ${p => p.theme.background.primary};
    &:focus {
      outline: none;
      box-shadow: rgb(59 148 191 / 30%) 0 0 0 4px;
      border-color: rgb(59, 148, 191);
    }
    &:disabled {
      color: ${({ theme }) => theme.color.grey500};
      border-color: ${({ theme }) => theme.color.grey300};
      cursor: not-allowed;
    }
  }
  textarea {
    min-height: 100px;
    padding: 8px 14px;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    height: auto;
    line-height: inherit;
    transition: all 0.3s;
    width: 100%;
    color: ${p => p.theme.text.primary};
    border: 1px solid ${p => p.theme.border.color.primary};
    background-color: ${p => p.theme.background.primary};
    &:focus {
      outline: none;
      box-shadow: rgb(59 148 191 / 30%) 0 0 0 4px;
      border-color: rgb(59, 148, 191);
    }
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  ${({ $inValid }) =>
    $inValid &&
    css`
      input,
      textarea {
        border-color: rgb(162, 18, 44);
        &:focus {
          box-shadow: rgba(220, 26, 62, 0.3) 0 0 0 4px;
          border-color: rgb(162, 18, 44);
        }
      }
    `}
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: rgb(197, 41, 42);
`;
