import React from 'react';
import { Button, Icon } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useNotifications } from 'src/hooks/useNotifications';

import * as Styled from './styles';
import { DRAWER_IDS, useDrawerStore } from 'src/store';

const TopNotificationButton = () => {
  const { isOpen, openDrawer } = useDrawerStore(DRAWER_IDS.NOTIFICATIONS);
  const theme = useTheme();
  const { unreadCount } = useNotifications({});

  const handleToggleNotificationsDrawer = e => {
    // TODO - Fix this
    e.preventDefault();
    e.stopPropagation();
    openDrawer();
  };

  return (
    <Styled.NotificationBlock>
      {!!unreadCount && (
        <Styled.Count $alignItems="center" $justifyContent="center">
          {unreadCount}
        </Styled.Count>
      )}
      <Button $circle onClick={handleToggleNotificationsDrawer}>
        <Icon
          size={20}
          name="notification"
          color={isOpen ? theme.color.success : theme.color.grey500}
        />
      </Button>
    </Styled.NotificationBlock>
  );
};

export default TopNotificationButton;
