import React from 'react';

import { Flex } from 'src/components/design-system';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';

import * as Styled from './styles';

const CustomTooltip = ({ payload }: TooltipProps<number, string>) => {
  if (!payload || !payload.length) return null;
  const { day, count, tasks } = payload[0].payload;
  return (
    <Styled.TooltipBlock>
      <Flex $gap={10}>
        <div>{day}</div>
        <strong>{`${count} task(s)`}</strong>
      </Flex>
      {tasks.map(({ _id, title }) => (
        <div key={_id}>{title}</div>
      ))}
    </Styled.TooltipBlock>
  );
};

const DashboardTasksByPeriodChart = ({ data, interval = 0 }) => {
  const maxYTickCount = Math.max(...data.map(({ count }) => count));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        height={350}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={<CustomTooltip />}
        />
        <XAxis
          dataKey="day"
          angle={-45}
          textAnchor="end"
          height={80}
          width={10}
          padding={'gap'}
          interval={interval}
        />
        <YAxis height={350} tickCount={maxYTickCount} allowDecimals={false} />
        <Tooltip />
        <Area
          height={350}
          connectNulls
          type="monotone"
          dataKey="count"
          stroke="#8884d8"
          fill="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DashboardTasksByPeriodChart;
