import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'src/components/design-system';

import FormContext from '../FormContext';
import FormPreviewCard from './FormPreviewCard/FormPreviewCard';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { FieldType } from '../types';

import * as Styled from './FormPreviewCard/styles';

const FormPreview = () => {
  const { state } = useContext(FormContext);
  const { elements } = state;

  const onSubmit = () => {};

  // const FormSchema = z.object({
  //   question1: z
  //     .string()
  //     .min(1, 'Name should be more than 1')
  //     .max(100, 'Name should be less than 100')
  //     .default(''),
  // });

  const FormSchema = z.object(
    Object.fromEntries(
      elements.map(field => {
        if (field.type === FieldType.Input && field.required) {
          return [
            field.name,
            z
              .string()
              .min(1, 'Value should be more than 1')
              .max(100, 'Value should be less than 100')
              .default(''),
          ];
        }

        if (field.type === FieldType.Boolean) {
          return [field.name, z.boolean().default(false).optional()];
        }

        if (field.type === FieldType.RatingScale) {
          return [field.name, z.number().default(1).optional()];
        }

        return [field.name, z.string().default('').optional()];
      }),
    ),
  );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  });

  return (
    <Styled.Test>
      <Styled.Container>
        <FormProvider {...form}>
          <form>
            {elements.map((element, index) => (
              <FormPreviewCard
                key={element.name}
                element={element}
                index={index + 1}
              />
            ))}
          </form>
          <Button onClick={form.handleSubmit(onSubmit)}>{'Submit'}</Button>
        </FormProvider>
        <pre>{JSON.stringify(state, null, 2)}</pre>
      </Styled.Container>
    </Styled.Test>
  );
};

export default FormPreview;
