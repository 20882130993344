import { Button } from 'src/components/design-system';
import styled from 'styled-components';

import { variantTypes } from './types';

type NoticeItemProps = {
  variant?: 'error' | 'success' | 'info';
};

const notificationVariant = ({ variant }: { variant?: variantTypes }) => {
  switch (variant) {
    case 'error':
      return 'border-top: 6px solid #e74c3c;';

    case 'success':
      return 'border-top: 6px solid #58a182;';

    default:
      return 'border-top: 6px solid #529CCA;';
  }
};

export const Container = styled.div`
  top: 0;
  right: 10px;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  display: flex;
  max-height: 100vh;
  overflow-y: auto;
  align-items: flex-end;
  padding: 10px;
  overflow-x: hidden;
`;

export const NoticeItem = styled.div<NoticeItemProps>`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: auto;
  min-width: 320px;
  max-width: 30%;
  line-height: 1.5;
  background: ${({ theme }) => theme.background.primary};
  box-shadow: 0 0 10px #0003;
  margin-bottom: 12px;
  pointer-events: auto;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  border-top: 6px solid #58a182;

  ${notificationVariant}
`;

export const NoticeTitle = styled.h3`
  margin: 0 0 10px 0;
`;
export const NoticeContent = styled.div`
  padding: 16px;
`;

export const NoticeClose = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
`;
