import React from 'react';
import { Drawer } from 'src/components/design-system';
import CustomFields from './CustomFields';
import { DRAWER_IDS, useDrawerStore } from 'src/store';

const CustomFieldsDrawer = () => {
  const { isOpen, closeDrawer } = useDrawerStore(DRAWER_IDS.CUSTOM_FIELDS);

  return (
    <Drawer width={400} open={isOpen} onClose={closeDrawer}>
      <CustomFields />
    </Drawer>
  );
};

export default CustomFieldsDrawer;
