import React from 'react';

import Chapter from '../Chapter/Chapter';

import { BooksQuery } from 'src/generated';

import * as Styled from './styles';

type BookCardProps = {
  book: BooksQuery['books'][0];
};

const Book = ({ book }: BookCardProps) => {
  const { title, poster, chapters } = book;

  return (
    <Styled.Book $alignItems="flex-start">
      <Styled.BookImg>
        <img src={poster} alt={title} />
      </Styled.BookImg>

      <Styled.BookInfo>
        <Styled.BookTitle>{title}</Styled.BookTitle>

        {chapters.map(chapter => (
          <Chapter key={chapter.url} chapter={chapter} />
        ))}
      </Styled.BookInfo>
    </Styled.Book>
  );
};

export default Book;
