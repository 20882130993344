import { makeVar } from '@apollo/client';

import { DEFAULT_FILTER } from 'src/widgets/Drawers/ProjectFilterDrawer/constants';

import { getThemeFromStorage } from 'src/helpers/theme';

import { Status } from 'src/generated';

type CurrentChapterVar = {
  chapterId: string;
  chapterTime: number;
};

export const currentThemeVar = makeVar(getThemeFromStorage() || 'system');
export const isOpenSidebarVar = makeVar(true);
export const editingCommentVar = makeVar<string | null>(null);

// Books
export const currentChapterVar = makeVar<CurrentChapterVar | null>(null);
export const openUploadBookModalVar = makeVar(false);
export const projectsFiltersVar = makeVar<(typeof DEFAULT_FILTER)[] | []>([]);

// statuses
export const editStatusVar = makeVar<Pick<
  Status,
  '_id' | 'title' | 'color'
> | null>(null);

// Project
export const openEditProjectModalVar = makeVar(false);
export const openInviteProjectMembersModalVar = makeVar(false);
