import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled(Flex)`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
`;

export const MinimizedTask = styled(Flex)`
  padding: 10px 10px 15px 10px;
  border-radius: 10px 10px 0 0;
  background: ${({ theme }) => theme.background.primary};
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  transform: translateY(3px);
  &:hover {
    background: ${({ theme }) => theme.background.secondary};
    transform: translateY(0);
  }
`;
