import React from 'react';
import { Drawer } from 'src/components/design-system';
import TaskDescriptionHistoryLoader from './TaskDescriptionHistory';
import { DRAWER_IDS, useDrawerStore } from 'src/store';

const TaskDescriptionHistoryDrawer = () => {
  const { isOpen, closeDrawer } = useDrawerStore(
    DRAWER_IDS.TASK_DESCRIPTION_HISTORY,
  );

  return (
    <Drawer open={isOpen} width={500} onClose={closeDrawer}>
      <TaskDescriptionHistoryLoader />
    </Drawer>
  );
};

export default TaskDescriptionHistoryDrawer;
