import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const Title = styled.h3`
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.secondary};
`;

export const MembersList = styled.div`
  height: 500px;
  overflow-y: auto;
`;

export const Member = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.background.secondary};
  }
`;
