import React, { useMemo, memo } from 'react';

import { Flex, Tooltip } from 'src/components/design-system';

import * as Styled from './styles';

const CommentReactions = ({ reactions, handleRemoveEmoji }) => {
  const groupedReactions = useMemo(() => {
    return reactions.reduce((acc, curr) => {
      if (!acc[curr.emoji]) {
        acc[curr.emoji] = [];
      }
      acc[curr.emoji].push(curr.user);
      return acc;
    }, []);
  }, [reactions]);

  return (
    <Flex $gap={6} $flexWrap="wrap">
      {Object.entries(groupedReactions).map(([emoji, users]: any) => (
        <Tooltip
          key={emoji}
          overlay={
            <>
              {users.map(({ fullName }: { fullName: string }) => (
                <div key={fullName}>{fullName}</div>
              ))}
            </>
          }
          placement="top"
        >
          <Styled.Reaction
            $gap={6}
            $alignItems="center"
            onClick={() => handleRemoveEmoji({ emoji, users })}
          >
            <em-emoji id={emoji} />
            {users.length}
          </Styled.Reaction>
        </Tooltip>
      ))}
    </Flex>
  );
};

export default memo(CommentReactions);
