import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from 'src/components/design-system';

import { DateFormats, DateHelpers } from '../../../common/helpers';

import CanvasCardMoreMenu from './CanvasCardMoreMenu';

import { CanvasBoardsQuery } from 'src/generated';

import * as Styled from './styles';

const IMAGE_PLACEHOLDER =
  'https://s3.eu-north-1.amazonaws.com/files.grawdanin.com/board_icon_16.png';

type CanvasCardProps = {
  canvasBoard: CanvasBoardsQuery['canvasBoards'][0];
  workspaceId: string;
};
const CanvasCard = ({ canvasBoard, workspaceId }: CanvasCardProps) => {
  const navigate = useNavigate();
  const { _id, title, createdBy, createdAt, previewImage } = canvasBoard;
  const { fullName } = createdBy || {};

  const handleGoToCard = () => {
    navigate(`/miro/${canvasBoard._id}`);
  };

  return (
    <Styled.Card onClick={handleGoToCard}>
      <CanvasCardMoreMenu boardId={_id} workspaceId={workspaceId} />

      <Styled.CardImage src={previewImage || IMAGE_PLACEHOLDER} />

      <Box $padding={10}>
        <Styled.CardTitle>{title}</Styled.CardTitle>

        <div>
          <strong>{'Created by:'}</strong>
          <div>{fullName}</div>
        </div>
        <div>
          <strong>{'Created At:'}</strong>
          <div>
            {DateHelpers.formatDate(createdAt, DateFormats.DayLongMonthYear)}
          </div>
        </div>
      </Box>
    </Styled.Card>
  );
};

export default CanvasCard;
