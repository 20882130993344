import React from 'react';
import { Drawer } from 'src/components/design-system';
import ProjectFilter from './ProjectFilter';
import { DRAWER_IDS, useDrawerStore } from 'src/store';

const ProjectFilterContainer = () => {
  const { isOpen, closeDrawer } = useDrawerStore(DRAWER_IDS.PROJECT_FILTER);

  return (
    <Drawer open={isOpen} width={300} onClose={closeDrawer}>
      <ProjectFilter />
    </Drawer>
  );
};

export default ProjectFilterContainer;
