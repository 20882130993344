import React from 'react';

import { useDrawerStore, DRAWER_IDS } from 'src/store';

import { Drawer } from 'src/components/design-system';

import TaskAdvancedFields from './TaskAdvancedFields';

const TaskAdvancedFieldsDrawer = () => {
  const { isOpen, closeDrawer } = useDrawerStore(
    DRAWER_IDS.TASK_ADVANCED_FIELDS,
  );

  return (
    <Drawer open={isOpen} width={500} onClose={closeDrawer}>
      <TaskAdvancedFields />
    </Drawer>
  );
};

export default TaskAdvancedFieldsDrawer;
