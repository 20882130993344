import React, { useMemo } from 'react';
import { useStatusesQuery, Status } from 'src/generated';
import { Select } from 'src/components/design-system';
import StatusesControl from 'src/components/design-system/Select/components/statuses/StatusesControl';
import StatusesOption from 'src/components/design-system/Select/components/statuses/StatusesOption';

type StatusesSelectProps = {
  projectId: string;
  statusId: string;
  onChange: (status: Status) => void;
};

const StatusesSelect = ({
  projectId,
  statusId,
  onChange,
}: StatusesSelectProps) => {
  const { data, loading } = useStatusesQuery({
    variables: {
      projectId,
    },
  });

  const currentStatus = useMemo(() => {
    const status = data?.statuses?.find(({ _id }) => _id === statusId);
    return status
      ? {
          _id: status._id,
          value: status._id,
          label: status.title,
          color: status.color,
        }
      : null;
  }, [data?.statuses, statusId]);

  return (
    <div>
      <Select
        isLoading={loading}
        isSearchable={false}
        value={currentStatus}
        onChange={onChange}
        options={data?.statuses.map(status => ({
          _id: status._id,
          value: status._id,
          label: status.title,
          color: status.color,
        }))}
        components={{
          Control: StatusesControl,
          Option: StatusesOption,
        }}
      />
    </div>
  );
};

export default StatusesSelect;
