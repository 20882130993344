import React, { useRef } from 'react';
import { Input, Flex, Button } from 'src/components/design-system';
import { useDrawerStore, DRAWER_IDS } from 'src/store';

import * as Styled from './styles';

const AdvancedField = ({ title, img, onCreate }) => {
  const { closeDrawer } = useDrawerStore(DRAWER_IDS.TASK_ADVANCED_FIELDS);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Flex $gap={10} direction="column">
      <Styled.Image src={img} alt={title} />
      <strong>{title}</strong>
      <Flex $alignItems="center" $gap={10}>
        <Input placeholder="Enter spreadsheet name" ref={inputRef} />
        <Button
          onClick={() => {
            const title = inputRef.current?.value.trim();
            if (!title) return;

            onCreate(title);
            closeDrawer();
          }}
        >
          {'Create'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default AdvancedField;
