import { googleLogout } from '@react-oauth/google';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { Box, Menu, Flex, Avatar } from 'src/components/design-system';

import { user_more_settings, USER_MORE_SETTINGS } from './constants';
import Auth from 'src/helpers/auth';
import { DRAWER_IDS, useDrawerStore, useUser, useUserStore } from 'src/store';

const UserSettings = () => {
  const { closeDrawer } = useDrawerStore(DRAWER_IDS.USER_SETTINGS);
  const navigate = useNavigate();
  const client = useApolloClient();
  const user = useUser();
  const setUser = useUserStore(state => state.setUser);

  const handleMenuClick = useCallback(
    (id: any) => {
      switch (id) {
        case USER_MORE_SETTINGS.SETTINGS:
          navigate('/settings/profile');
          break;
        case USER_MORE_SETTINGS.LOGOUT:
          googleLogout();
          setUser(null);
          client.resetStore();
          Auth.logout();
          break;
        default:
          break;
      }

      closeDrawer();
    },
    [client, closeDrawer, navigate, setUser],
  );

  return (
    <Box $padding={10}>
      <Flex $alignItems="center" margin={[0, 0, 20, 0]}>
        <Avatar user={user} />
        <Flex direction="column">
          <Box $bold>{user?.fullName}</Box>
          <Box>{user?.email}</Box>
        </Flex>
      </Flex>
      <Menu
        clean
        options={user_more_settings}
        handleMenuClick={handleMenuClick}
      />
    </Box>
  );
};

export default UserSettings;
