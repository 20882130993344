import React, { CSSProperties } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ProjectSmallInfo from '../../../components/ProjectSmallInfo/ProjectSmallInfo';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ProjectViews } from 'src/pages/Project/types';

import * as Styled from './styles';

const ProjectsListItem = ({ project }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { _id, title, color } = project;
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: _id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  const handleClick = () => {
    navigate(`/projects/${_id}?view=${ProjectViews.Kanban}`);
  };

  return (
    <Styled.ProjectItem
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      $active={_id === params.id}
    >
      <ProjectSmallInfo
        title={title}
        color={color}
        isDragging={isDragging}
        isDraggable
        onClick={handleClick}
      />
    </Styled.ProjectItem>
  );
};

export default ProjectsListItem;
