import { useNotification } from 'src/components/design-system';

import {
  useUpdateUserProfileMutation,
  useUpdateUserMutation,
  UpdateUserProfileInput,
  UpdateUserMutationVariables,
  useUpdateUserMinimizedTasksMutation,
  UpdateUserMinimizedTasksMutationVariables,
} from 'src/generated';

export const useUser = () => {
  const notification = useNotification();

  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [updateUser, { loading }] = useUpdateUserMutation();
  const [updateUserMinimizedTasks] = useUpdateUserMinimizedTasksMutation();

  const onUpdateUserProfile = async ({
    firstName,
    lastName,
    role,
    file,
  }: UpdateUserProfileInput) => {
    await updateUserProfile({
      variables: {
        firstName,
        lastName,
        role,
        file,
      },
    });

    notification.show({
      message: 'Your profile was updated successfully',
      variant: 'success',
    });
  };

  const onUpdateUser = async ({
    pushNotificationToken,
    projectsOrder,
  }: UpdateUserMutationVariables) => {
    await updateUser({
      variables: {
        pushNotificationToken,
        projectsOrder,
      },
    });
  };

  const onUpdateUserMinimizedTasks = async ({
    addMinimizedTaskId,
    removeMinimizedTaskId,
  }: UpdateUserMinimizedTasksMutationVariables) => {
    await updateUserMinimizedTasks({
      variables: {
        addMinimizedTaskId,
        removeMinimizedTaskId,
      },
    });
  };

  return {
    onUpdateUser,
    onUpdateUserProfile,
    updateUserProfileLoading: loading,
    onUpdateUserMinimizedTasks,
  };
};
