import { gql } from '@apollo/client';

import { TASK_FRAGMENT } from '../fragments';

gql`
  mutation CreateTask(
    $workspaceId: ID!
    $projectId: ID!
    $title: String!
    $statusId: ID
    $parentId: ID
    $dueDate: Date
  ) {
    createTask(
      workspaceId: $workspaceId
      projectId: $projectId
      statusId: $statusId
      title: $title
      parentId: $parentId
      dueDate: $dueDate
    ) {
      _id
      workspaceId
      title
      priority
      statusId
      dueDate
      startDate
      storyPoints
      projectId {
        _id
        color
        completedStatusId
        unstagedStatusId
      }
      assignee {
        _id
        email
        fullName
        profilePicture
      }
      parentId {
        _id
        title
      }
      labels {
        _id
        title
        color
      }
      attachments {
        _id
        url
        fileName
      }
    }
  }
`;

gql`
  mutation DeleteTasks($taskIds: [ID!]!, $projectId: ID!) {
    deleteTasks(taskIds: $taskIds, projectId: $projectId) {
      _id
    }
  }
`;

export const ADD_ATTACHMENTS_TO_TASK = gql`
  mutation AddAttachmentsToTask(
    $taskId: ID!
    $projectId: ID!
    $attachments: [Upload!]!
  ) {
    addAttachmentsToTask(
      input: {
        taskId: $taskId
        projectId: $projectId
        attachments: $attachments
      }
    ) {
      _id
      attachments {
        _id
        url
        fileName
        size
        type
      }
    }
  }
`;

gql`
  mutation DuplicateTask($taskId: ID!) {
    duplicateTask(taskId: $taskId) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

gql`
  mutation UpdateTask($updateTaskInput: UpdateTaskInput!) {
    updateTask(updateTaskInput: $updateTaskInput) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

gql`
  mutation ApplyTaskTemplate($taskId: ID!, $templateId: ID!) {
    applyTaskTemplate(taskId: $taskId, templateId: $templateId) {
      _id
      title
      description
      dueDate
      isUrgent
      priority
      startDate
      storyPoints
      assignee {
        _id
        email
        fullName
        profilePicture
      }
    }
  }
`;
