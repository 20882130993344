import UserSettingsContainer from 'src/widgets/Drawers/UserSettingsDrawer/UserSettingsContainer';
import { useEffect } from 'react';
import { useNotifications } from './hooks/useNotifications';
import { useNotificationSubscriptions } from 'src/hooks/useNotificationSubscriptions';

import AudioBarContainer from 'src/widgets/AudioBar/AudioBarContainer';
import NotificationsWidget from 'src/widgets/Drawers/NotificationsDrawer/Notifications';
import TaskModalContainer from 'src/widgets/TaskModal/TaskModalContainer';
import MinimizedTaskList from 'src/widgets/MinimizedTaskList/MinimizedTaskList';
import { refreshPushNotificationToken } from './config/firebase';
import { useUser as useUserStore } from './store';

const Root = () => {
  const user = useUserStore();
  useNotificationSubscriptions();
  useNotifications({});

  useEffect(() => {
    if (!user?.pushNotificationToken) return;
    // validate token for push notifications
    refreshPushNotificationToken();
  }, [user?.pushNotificationToken]);

  return (
    <>
      <MinimizedTaskList />
      <TaskModalContainer />
      <AudioBarContainer />
      <NotificationsWidget />
      <UserSettingsContainer />
    </>
  );
};

export default Root;
