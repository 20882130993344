import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const TimeTracking = styled(Flex)`
  padding: 0 4px 4px 4px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: 0 0 ${({ theme }) => theme.border.radius.sm}
    ${({ theme }) => theme.border.radius.sm};
  border-top: 4px solid ${({ theme }) => theme.color.success};
  &:hover {
    background-color: ${({ theme }) => theme.background.secondary};
  }
`;

export const TimeTrackingText = styled.div`
  padding: 3px 3px 0 6px;
`;
