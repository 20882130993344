import { Button } from 'src/components/design-system';
import styled, { css } from 'styled-components';

type EventProps = {
  $isCompleted?: boolean;
};

type DayCellProps = {
  isToday?: boolean;
};

export const AddTaskButton = styled(Button)`
  opacity: 0;
  transition: opacity 0.2s;
`;

export const FullCalendarBlock = styled.div`
  .fc-event {
    min-height: 44px;
    border: none;
    border-radius: ${({ theme }) => theme.border.radius.sm};
  }

  .fc .fc-multimonth-title {
    padding: 0;
  }

  .fc .fc-multimonth {
    border: none;
  }

  .fc-theme-standard .fc-popover {
    border: none;
  }

  .fc-daygrid-day-frame {
    min-height: 300px !important;
  }

  .fc .fc-cell-shaded,
  .fc .fc-day-disabled {
    background: ${({ theme }) => theme.background.tertiary};
    .fc-daygrid-day-frame {
      background: ${({ theme }) => theme.background.tertiary};
    }
  }

  .fc-day {
    &:hover {
      ${AddTaskButton} {
        opacity: 1;
      }
    }
  }
`;

export const TaskName = styled.div`
  margin-left: 10px;
  font-size: 14px;
`;

export const DayCell = styled.div<DayCellProps>`
  font-weight: bold;
  ${({ isToday }) =>
    isToday &&
    css`
      color: ${({ theme }) => theme.color.navi};
    `}
`;

export const Event = styled.div<EventProps>`
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: 5px;
  color: #fff;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  overflow: hidden;

  ${p =>
    p.$isCompleted &&
    `
    opacity: 0.7;

    ${TaskName} {
      text-decoration: line-through;}
  `}
`;
