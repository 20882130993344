import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Select } from 'src/components/design-system';
import PriorityControl from 'src/components/design-system/Select/components/priority/PriorityControl';
import PriorityOption from 'src/components/design-system/Select/components/priority/PriorityOption';

import StoryPointsOption from 'src/components/design-system/Select/components/storyPoints/StoryPointsOption';
import StoryPointsSingleValue from 'src/components/design-system/Select/components/storyPoints/StoryPointsSingleValue';

import { useTask } from 'src/hooks/useTask';

import { priorities } from 'src/common/constants';

import DueDate from '../../../pages/Project/components/Board/Action/DueDate/DueDate';
import { storyPointsList } from '../../../pages/Project/components/Board/Action/constants';
import TaskAssignee from '../../../pages/Project/components/Task/TaskAssignee/TaskAssignee';
import StatusesSelect from 'src/widgets/TaskModal/components/StatusesSelect/StatusesSelect';

import * as Styled from './styles';
import { GetTasksQuery } from 'src/generated';

type TaskModalLeftProps = {
  action: GetTasksQuery['getTasks'][0];
  onClose: () => void;
};

const TaskModalLeft = ({ action, onClose }: TaskModalLeftProps) => {
  const { t } = useTranslation();

  const { _id, priority, statusId, storyPoints, assignee } = action;

  const { onDeleteTasks, onUpdateTask } = useTask();

  const handleDeleteAction = () => {
    onDeleteTasks({ taskIds: [_id] });
    onClose();
  };

  const currentPriority = priorities.find(({ value }) => value === priority);
  const taskStoryPoints = storyPointsList.find(
    ({ value }) => value === storyPoints,
  );

  return (
    <Styled.Left>
      <div>
        <Styled.LeftItem>
          <h2>{t('task.assignee.title')}</h2>
          <TaskAssignee assignee={assignee} />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.dueDate.title')}</h2>
          <DueDate task={action} />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.status.title')}</h2>
          <StatusesSelect
            statusId={statusId}
            projectId={action?.projectId?._id!}
            onChange={({ _id: statusId }) => {
              onUpdateTask({ taskId: _id, statusId });
            }}
          />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.priority.title')}</h2>
          <Select
            isSearchable={false}
            options={priorities}
            value={
              currentPriority ? currentPriority : { value: null, label: 'None' }
            }
            onChange={({ value }) => {
              onUpdateTask({
                taskId: _id,
                priority: value,
              });
            }}
            components={{
              Control: PriorityControl,
              Option: PriorityOption,
            }}
          />
        </Styled.LeftItem>

        <Styled.LeftItem>
          <h2>{t('task.storyPoints.title')}</h2>
          <Select
            isSearchable={false}
            options={storyPointsList}
            value={taskStoryPoints?.value !== null ? taskStoryPoints : null}
            onChange={data => {
              // data can be null if the user select none story points
              if (data === null) return;
              onUpdateTask({
                taskId: _id,
                storyPoints: data.value,
              });
            }}
            components={{
              SingleValue: StoryPointsSingleValue,
              Option: StoryPointsOption,
            }}
          />
        </Styled.LeftItem>
      </div>

      <Flex $gap={16} $pushDown direction="column">
        <Button $danger onClick={handleDeleteAction}>
          {t('task.buttons.delete')}
        </Button>
      </Flex>
    </Styled.Left>
  );
};

export default TaskModalLeft;
