import React from 'react';

import Volume from '../Volume/Volume';

import { Container } from './styles';

const RightBlock = ({ audioRef }) => {
  return (
    <Container $justifyContent="flex-end">
      <Volume audioRef={audioRef} />
    </Container>
  );
};

export default RightBlock;
