import { Flex, Icon } from 'src/components/design-system';
import styled, { css } from 'styled-components';

type BoxProps = {
  $backgroundColor: string;
};

type ContainerProps = {
  $isDragging?: boolean;
};

export const ReorderIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  cursor: grab;
  display: none;
`;

export const Container = styled(Flex)<ContainerProps>`
  position: relative;
  width: 100%;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      ${ReorderIcon} {
        display: block;
      }
    `}

  &:hover {
    ${ReorderIcon} {
      display: block;
    }
  }
`;

export const BoxIcon = styled.div<BoxProps>`
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: #ffffff;
`;

export const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
