import styled from 'styled-components';

export const Row = styled.tr`
  cursor: auto;
`;

export const RowTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
export const RowTitleCount = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.grey500};
`;
