import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Button, Switch } from 'src/components/design-system';

import * as Styled from './styles';

const Header = ({
  calendarRef,
  currentDate,
  setIsWeekendsVisible,
  isWeekendsVisible,
}) => {
  const { t } = useTranslation();
  const calendarApi = () => calendarRef.current?.getApi();

  const handleGoToPrev = () => {
    calendarApi().prev();
  };

  const handleGoToNext = () => {
    calendarApi().next();
  };

  const handleGoToToday = () => {
    calendarApi().today();
  };

  return (
    <Styled.Header>
      <Flex $gap={10} $alignItems="center">
        <Button onClick={handleGoToPrev}>{t('project.calendar.prev')}</Button>
        <Button onClick={handleGoToNext}>{t('project.calendar.next')}</Button>
        <Button onClick={handleGoToToday}>{t('project.calendar.today')}</Button>
        <Styled.Date>{currentDate?.view.title}</Styled.Date>

        <Flex $gap={10} $alignItems="center" $pushRight>
          <Flex $alignItems="center" $gap={10}>
            <span>{'Weekends:'}</span>
            <Switch
              id="toggleWeekends"
              isChecked={isWeekendsVisible}
              onChange={() => {
                setIsWeekendsVisible(!isWeekendsVisible);
                calendarApi().setOption('weekends', !isWeekendsVisible);
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Styled.Header>
  );
};

export default Header;
