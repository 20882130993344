import React, { useState, useRef, useContext, createContext } from 'react';

import { Modal, Flex, Box } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import * as Styled from './styles';

type ConfirmContextType = (options: ConfirmOptions) => Promise<void>;

const ConfirmContext = createContext<ConfirmContextType>(
  {} as ConfirmContextType,
);

type ConfirmOptions = {
  title?: string;
  message?: string;
};

interface ConfirmPromise {
  resolve: () => void;
  reject: () => void;
}

export const useConfirm = () => useContext(ConfirmContext);

export const ConfirmServiceProvider = ({ children }) => {
  const theme = useTheme();
  const [options, setOptions] = useState<ConfirmOptions | null>(null);
  const awaitingPromiseRef = useRef<ConfirmPromise | null>(null);

  const openModal = (options: ConfirmOptions) => {
    setOptions(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOptions(null);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setOptions(null);
  };

  const { message } = options || {};

  const isOpen = options !== null;

  return (
    <>
      {/* eslint-disable-next-line react/no-children-prop */}
      <ConfirmContext.Provider value={openModal} children={children} />

      {isOpen && (
        <Modal
          width={400}
          isOpen={true}
          onRequestClose={handleClose}
          title="Are you sure?"
          style={{
            overlay: {
              zIndex: 1000,
            },
          }}
        >
          <Box>{message && <p>{message}</p>}</Box>
          <Flex>
            <Styled.ConfirmButton
              background={theme.color.success}
              $htmlType="text"
              onClick={handleConfirm}
            >
              {'Yes, I agree'}
            </Styled.ConfirmButton>
            <Styled.ConfirmButton
              background={theme.color.danger}
              $htmlType="text"
              onClick={handleClose}
            >
              {'Cancel'}
            </Styled.ConfirmButton>
          </Flex>
        </Modal>
      )}
    </>
  );
};
