import React, { useContext, useEffect, useRef } from 'react';

import useOnClickOutside from 'src/hooks/common/useOnClickOutside';

import FormContext from '../FormContext';
import ContentEditable from '../FormDesign/FormBuilder/ContentEditable/ContentEditable';
import Rating from '../FormFields/Rating/Rating';
import FormCardSettings from './FormCardSettings';

import { FieldType } from '../types';

import * as Styled from './styles';

const FormCard = ({ element, index }) => {
  const { title, name, description, required, type } = element;
  const cardRef = useRef(null);

  const {
    state: { activeElement, elements },
    dispatch,
  } = useContext(FormContext);

  const isActive = activeElement === name;

  const handleAddActiveElements = () => {
    dispatch({ activeElement: name });
  };

  useOnClickOutside(cardRef, () => {
    // setActiveElement(null);
  });

  useEffect(() => {
    // If there is only one element in the list, it is automatically selected
    if (elements.length === 1) {
      dispatch({ activeElement: name });
    }
  }, [dispatch, elements.length, name]);

  const handleUpdateElement = (value, key) => {
    dispatch({
      elements: elements.map(element => {
        if (element.name === name) {
          return {
            ...element,
            [key]: value,
          };
        }

        return element;
      }),
    });
  };

  return (
    <Styled.Card
      ref={cardRef}
      $active={isActive}
      onClick={handleAddActiveElements}
    >
      <div>
        <span>{`${index}. `}</span>
        <ContentEditable
          value={title || name}
          onBlur={value => {
            handleUpdateElement(value, 'title');
          }}
        />
        {required && <Styled.Sup>{' *'}</Styled.Sup>}
      </div>
      <ContentEditable
        value={description}
        onBlur={value => {
          handleUpdateElement(value, 'description');
        }}
      />
      {(() => {
        switch (type) {
          case FieldType.Input:
            return <input type="text" readOnly />;
          case FieldType.TextArea:
            return <textarea readOnly />;
          case FieldType.RadioGroup:
            return <div>{'RadioGroup'}</div>;
          case FieldType.RatingScale:
            return <Rating />;
          case FieldType.Checkboxes:
            return <div>{'Checkboxes'}</div>;
          case FieldType.Boolean:
            return <div>{'Boolean'}</div>;
          default:
            return null;
        }
      })()}
      <Styled.FormCardSettings $active={isActive}>
        <FormCardSettings element={element} />
      </Styled.FormCardSettings>
    </Styled.Card>
  );
};

export default FormCard;
