import React from 'react';

import { Flex } from 'src/components/design-system';

import { useTaskHistory } from 'src/hooks/useTaskHistory';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import formatDistance from 'date-fns/formatDistance';

import { TaskHistoryList } from './styles';

const TaskHistory = () => {
  const { taskHistory } = useTaskHistory();

  return (
    <TaskHistoryList>
      {taskHistory.map(({ _id, body, createdAt }) => (
        <Flex $alignItems="center" $gap={5} $flexWrap="wrap" key={_id}>
          <span>
            {body} {' on '}
          </span>
          <span>{DateHelpers.formatDate(createdAt, DateFormats.FullDate)}</span>
          <span>
            {' ('}
            {formatDistance(new Date(createdAt), new Date(), {
              includeSeconds: true,
              addSuffix: true,
            })}
            {')'}
          </span>
        </Flex>
      ))}
    </TaskHistoryList>
  );
};

export default TaskHistory;
