import React from 'react';
import Select from 'react-select';

import { useTheme } from 'styled-components';

const CustomSelect = props => {
  const currentTheme = useTheme();

  return (
    <Select
      {...props}
      styles={{
        menu: baseStyles => {
          return {
            ...baseStyles,
            margin: '0',
          };
        },
        menuList: baseStyles => {
          return {
            ...baseStyles,
            padding: '0',
            '::-webkit-scrollbar': {
              width: '4px',
              height: '0px',
            },
            '::-webkit-scrollbar-track': {
              background: currentTheme.background.hover,
            },
            '::-webkit-scrollbar-thumb': {
              background: currentTheme.background.selected,
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
          };
        },
        control: baseStyles => {
          return {
            ...baseStyles,
            borderColor: currentTheme.border.color.secondary,
            boxShadow: 'none',
            '&:hover': {
              borderColor: currentTheme.border.color.primary,
            },
          };
        },
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          danger: 'red',
          dangerLight: 'orange',
          neutral0: currentTheme.background.primary,
          neutral5: currentTheme.border.color.primary,
          neutral10: 'orange',
          neutral20: currentTheme.border.color.secondary,
          neutral30: 'gray',
          neutral40: currentTheme.text.secondary,
          neutral50: currentTheme.text.secondary,
          neutral60: currentTheme.border.color.primary,
          neutral70: 'blue',
          neutral80: currentTheme.text.secondary,
          neutral90: 'yellow',
          primary: currentTheme.color.success,
          primary25: currentTheme.background.hover,
          primary50: currentTheme.background.hover,
          primary75: 'yellow',
        },
      })}
    />
  );
};

export default CustomSelect;
