import React, { useCallback, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import styled from 'styled-components';

import { useProjects } from 'src/hooks/useProjects';
import { useStatuses } from 'src/hooks/useStatuses';
import { useTask } from 'src/hooks/useTask';

import AddColumn from './Column/AddColumn/AddColumn';
import Column from './Column/Column';
import { GET_PROJECT_TASKS_FOR_BOARD_VIEW } from 'src/graphql/actions';
import { dndReorder } from 'src/helpers/dnd';
import { findKanbanTaskById } from 'src/helpers/task';

const Board = ({ kanbanTasks }) => {
  const { onUpdateProject } = useProjects();
  const client = useApolloClient();

  const { id } = useParams<{ id: string }>();
  const [dragOverlay, setDragOverlay] = useState(null);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const { onUpdateTask } = useTask();

  const { onUpdateStatus } = useStatuses();

  const handleDragStart = useCallback(
    event => {
      const task = findKanbanTaskById(kanbanTasks, event.draggableId);
      if (!task) return;
      setDragOverlay(task);
    },
    [kanbanTasks],
  );

  const handleDragUpdate = event => {
    if (!event.destination) {
      return;
    }

    const destinationIndex = event.destination.index;
    const droppableId = event.destination.droppableId;

    const domQuery = `[data-rbd-droppable-id='${droppableId}']`;
    const droppableDOM = document.querySelector(domQuery);

    if (!droppableDOM) {
      return;
    }

    // @ts-ignore
    const arr = [...droppableDOM.children];

    const clientY =
      parseFloat(window.getComputedStyle(droppableDOM).paddingTop) +
      arr.slice(0, destinationIndex).reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const taskMargin =
          parseFloat(style.marginBottom) + parseFloat(style.marginTop);
        return total + curr.clientHeight + taskMargin;
      }, 0);

    setPlaceholderProps({
      clientY,
    });
  };

  const handleDragEnd = result => {
    setPlaceholderProps({});
    setDragOverlay(null);
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // reordering column
    if (type === 'COLUMN') {
      const reorderedColumns = dndReorder(
        kanbanTasks,
        source.index,
        destination.index,
      );

      client.writeQuery({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId: id },
        data: {
          kanbanTasks: reorderedColumns,
        },
      });

      // @ts-ignore
      const reorderedColumnsIds = reorderedColumns.map(({ _id }) => _id);

      onUpdateProject({
        projectId: id!,
        boardsSortList: reorderedColumnsIds as string[],
      });
      return;
    }

    const boardTasks = kanbanTasks.find(
      ({ _id }) => _id === source.droppableId,
    ).actions;

    // add to destination column
    const currentAction = boardTasks.find(({ _id }) => _id === draggableId);

    // reorder task in same column
    if (source.droppableId === destination.droppableId) {
      const reorderedorder = dndReorder(
        boardTasks,
        source.index,
        destination.index,
      );

      const newBoardsWithTaks = kanbanTasks.map(board => {
        if (board._id === destination.droppableId) {
          return {
            ...board,
            actions: reorderedorder,
          };
        }

        return board;
      });

      client.writeQuery({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId: id },
        data: {
          kanbanTasks: newBoardsWithTaks,
        },
      });

      // @ts-ignore
      const list = reorderedorder.map(({ _id }) => _id);

      // save task order in same column
      onUpdateStatus({ statusId: destination.droppableId, taskOrder: list });
    } else {
      // move task to other column

      // current column
      // source.droppableId;

      // next column
      // destination.droppableId;

      if (source.droppableId === destination.droppableId) return;

      const nextBoardTasks = kanbanTasks.find(
        ({ _id }) => _id === destination.droppableId,
      ).actions;

      const nextColumnStatus = kanbanTasks.find(
        ({ _id }) => _id === destination.droppableId,
      ).title;

      const updatedTask = { ...currentAction, status: nextColumnStatus };

      const p = [...nextBoardTasks];

      p.splice(destination.index, 0, updatedTask);

      const taskOrder = p.map(({ _id }) => _id);

      const test2 = kanbanTasks.map(boardWithTask => {
        if (boardWithTask._id === source.droppableId) {
          return {
            ...boardWithTask,
            tasksCount: boardWithTask.tasksCount - 1,
            actions: boardWithTask.actions.filter(
              action => action._id !== currentAction._id,
            ),
          };
        }

        if (boardWithTask._id === destination.droppableId) {
          return {
            ...boardWithTask,
            tasksCount: boardWithTask.tasksCount + 1,
            actions: p,
          };
        }

        return boardWithTask;
      });

      client.writeQuery({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId: id },
        data: {
          kanbanTasks: test2,
        },
      });

      // save task order in other column
      onUpdateStatus({ statusId: destination.droppableId, taskOrder });

      onUpdateTask({
        taskId: currentAction._id,
        statusId: destination.droppableId,
        shouldUpdateCache: false,
      });
    }
  };

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable droppableId="boards" direction="horizontal" type="COLUMN">
        {provided => (
          <Columns ref={provided.innerRef} {...provided.droppableProps}>
            {kanbanTasks.map((column, index) => {
              return (
                <Column
                  key={column._id}
                  column={column}
                  index={index}
                  placeholderProps={placeholderProps}
                  dragOverlay={dragOverlay}
                />
              );
            })}
            {provided.placeholder}
            <AddColumn />
          </Columns>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Columns = styled.div`
  position: relative;
  padding: 0 24px;
  display: flex;
  overflow-x: auto;
  height: 100%;
  background: ${props => props.theme.background.primary};
  // hide scrollbar for board view
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Board;
