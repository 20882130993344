import React from 'react';

import { Flex, Skeleton } from 'src/components/design-system';

import { randomIntFromInterval } from 'src/helpers/numbers';

type BoardSkeletonProps = {
  columnCount?: number;
};

const BoardSkeleton = ({ columnCount = 3 }: BoardSkeletonProps) => {
  return (
    <Flex $flexWrap="wrap">
      <Flex $gap={20} $padding={[0, 24]} $flexWrap="nowrap">
        {Array.from({ length: columnCount }).map((_, index) => (
          <Flex key={index} $gap={10} direction="column" width="250px">
            {Array.from({ length: randomIntFromInterval(1, 3) }).map(
              (_, index) => (
                <Flex key={index} direction="column">
                  <Flex direction="column" $padding={[20, 0]} $gap={10}>
                    <Skeleton width="100%" height={15} />
                    <Skeleton width="100%" height={25} />
                  </Flex>
                  <Flex $gap={10} direction="column" $padding={[10, 0, 25, 0]}>
                    <Skeleton width="90%" height={10} />
                    <Skeleton width="100%" height={10} />
                    <Skeleton width="80%" height={10} />
                    <Skeleton width="100%" height={10} />
                  </Flex>
                </Flex>
              ),
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default BoardSkeleton;
