import React from 'react';
import { Action } from 'src/generated';
import { Icon, Button } from 'src/components/design-system';

import * as Styled from './styles';

type MinimizedTaskProps = {
  task: Pick<Action, '_id' | 'title'>;
  onClose: (taskId: string) => void;
  onTaskClick: (args: { taskId: string }) => void;
};

const MinimizedTask = ({ task, onClose, onTaskClick }: MinimizedTaskProps) => {
  const { _id, title } = task;
  return (
    <Styled.MinimizedTask
      $gap={4}
      $alignItems="center"
      onClick={() => onTaskClick({ taskId: _id })}
    >
      {title}
      <Button
        size="small"
        $htmlType="text"
        $padding="0"
        onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.stopPropagation();
          onClose(_id);
        }}
        icon={<Icon name="close" />}
      />
    </Styled.MinimizedTask>
  );
};

export default MinimizedTask;
