import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal, { Props } from 'react-modal';

import { Button, Icon, Tooltip } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { Overlay, Content, CloseButton, Title } from './styles';

interface ModalProps extends Props {
  width: string | number;
  showCloseButton?: boolean;
  showTopBorder?: boolean;
  title?: string;
  customHeader?: () => React.ReactNode;
}

const Modal = ({
  width = 1000,
  showCloseButton = true,
  showTopBorder = true,
  children,
  style,
  title,
  customHeader,
  ...rest
}: ModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ReactModal
      {...rest}
      contentLabel="modal"
      ariaHideApp={false}
      overlayElement={(props, contentElement) => (
        <Overlay {...props} style={{ background: 'rgba(110,118,129,0.4)' }}>
          {contentElement}
        </Overlay>
      )}
      contentElement={(props, children) => (
        <Content {...props}>
          {customHeader && customHeader()}
          {showCloseButton && (
            <Tooltip overlay={t('general.close')} placement="top">
              <CloseButton>
                <Button
                  size="little"
                  $htmlType="text"
                  onClick={rest.onRequestClose}
                  icon={
                    <Icon name="close" size={24} color={theme.text.primary} />
                  }
                />
              </CloseButton>
            </Tooltip>
          )}
          {title && <Title>{title}</Title>}
          {children}
        </Content>
      )}
      style={{
        overlay: {
          ...style?.overlay,
        },
        content: {
          inset: '0',
          padding: '20px',
          border: 'none',
          borderTop: showTopBorder ? `8px solid #529cca` : 'none',
          overflow: 'inherit',
          position: 'relative',
          width: `${width}px`,
          borderRadius: theme.border.radius.sm,
          backgroundColor: theme.background.primary,
          color: theme.text.primary,
          ...style?.content,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
