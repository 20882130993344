import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Flex, LoadingIndicator } from 'src/components/design-system';

import ProjectActivity from './ProjectActivity/ProjectActivity';
import ProjectOverview from './ProjectOverview/ProjectOverview';
import { useUser } from 'src/store';

import { useNotificationsQuery } from 'src/generated';

const Overview = () => {
  const { workspaceId } = useUser();
  const { t } = useTranslation();
  const { id: projectId } = useParams<{ id: string }>();

  const { data, loading } = useNotificationsQuery({
    variables: {
      workspaceId,
      projectId,
      isPrivate: false,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (!data?.notifications.length) {
    return (
      <Box $padding={20}>
        <p>{t('project.activity.noActivity')}</p>
      </Box>
    );
  }

  return (
    <Flex>
      <Flex $flex="1">
        <Box $padding={20} width="100%">
          <h2>{t('project.activity.title')}</h2>

          {data.notifications.map(notification => (
            <ProjectActivity
              key={notification._id}
              notification={notification}
            />
          ))}
        </Box>
      </Flex>
      <ProjectOverview />
    </Flex>
  );
};

export default Overview;
