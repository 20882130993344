import React, { useCallback, useState } from 'react';

import { Button, Flex, Icon } from 'src/components/design-system';

import { useQueryParams } from 'src/hooks/useQueryParams';
import { useTask } from 'src/hooks/useTask';
import { useTaskTemplate } from 'src/hooks/useTaskTemplate';

import { useUpdateIsOpenTaskTemplateModal, useUserWorkspace } from 'src/store';

import { useTaskTemplatesQuery } from 'src/generated';

import * as Styled from './styles';

const TaskTemplatesList = () => {
  const workspaceId = useUserWorkspace();
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;
  const { onApplyTaskTemplate } = useTask();
  const { onDeleteTaskTemplate } = useTaskTemplate();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  );
  const closeTaskTemplateModal = useUpdateIsOpenTaskTemplateModal();

  const { data, loading } = useTaskTemplatesQuery({
    variables: { workspaceId },
  });

  const handleApplyTemplate = () => {
    if (!selectedTemplateId) return;
    onApplyTaskTemplate({ taskId, templateId: selectedTemplateId });
    closeTaskTemplateModal(false);
  };

  const handleSelectTemplate = useCallback(
    (templateId: string) => {
      setSelectedTemplateId(() => {
        if (selectedTemplateId === templateId) {
          return null;
        }
        return templateId;
      });
    },
    [selectedTemplateId],
  );

  if (!data?.taskTemplates.length && !loading) {
    return <p>{'You have no task templates'}</p>;
  }

  return (
    <div>
      {data?.taskTemplates.map(({ _id, title }) => (
        <Styled.Template
          $gap={10}
          $alignItems="center"
          key={_id}
          selected={selectedTemplateId === _id}
          onClick={() => handleSelectTemplate(_id)}
        >
          <Styled.Title>{title}</Styled.Title>
          <Button
            onClick={e => {
              e.preventDefault();
              onDeleteTaskTemplate({ templateId: _id });
            }}
          >
            <Icon name="trash" />
          </Button>
        </Styled.Template>
      ))}

      <Flex $gap={10} $justifyContent="flex-end" $padding={[20, 0, 0]}>
        <Button
          $htmlType="primary"
          onClick={handleApplyTemplate}
          disabled={!selectedTemplateId}
        >
          {'Apply Template'}
        </Button>
      </Flex>
    </div>
  );
};

export default TaskTemplatesList;
