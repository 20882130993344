import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Flex, Icon } from 'src/components/design-system';

import { sidebarMenu } from 'src/layouts/MainLayout/constants';

import * as Styled from './styles';

const HeaderTitle = () => {
  const { pathname } = useLocation();

  const menu = useMemo(() => {
    return sidebarMenu.find(item => item.path === pathname);
  }, [pathname]);

  if (!menu) return null;

  const { title, icon } = menu;

  return (
    <Flex $gap={8} $alignItems="center">
      <Styled.IconBlock $alignItems="center" $justifyContent="center">
        <Icon name={icon} color="#fff" />
      </Styled.IconBlock>
      <h3>{title}</h3>
    </Flex>
  );
};

export default HeaderTitle;
