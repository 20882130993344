import Picker from '@emoji-mart/react';

import { useReactiveVar } from '@apollo/client';

import { currentThemeVar } from '../../graphql/vars';
import data from '@emoji-mart/data';

export interface EmojiPickerProps {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  onEmojiSelect: Function;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  onClickOutside?: Function;
}

export const EmojiPicker = ({
  onEmojiSelect,
  onClickOutside,
}: EmojiPickerProps) => {
  const theme = useReactiveVar(currentThemeVar);

  const pickerTheme = theme === 'light' ? 'light' : 'dark';

  return (
    <Picker
      data={data}
      theme={pickerTheme}
      onEmojiSelect={onEmojiSelect}
      onClickOutside={onClickOutside}
    />
  );
};

export default EmojiPicker;
