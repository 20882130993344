import React, { useCallback } from 'react';

import { Flex, Button, Skeleton, Icon } from 'src/components/design-system';

const Sticker = ({
  attributes,
  element,
  children,
  fetchGiphy,
  removeGiphy,
  handleAddComment,
  giphyLoading,
}) => {
  const { url } = element;

  const handleShowNextGif = useCallback(() => {
    fetchGiphy();
  }, [fetchGiphy]);

  return (
    <div {...attributes}>
      <Flex $gap={16} contentEditable={false}>
        <div>
          {giphyLoading ? (
            <Skeleton width={200} height={200} />
          ) : (
            <img
              alt={element.alt}
              src={url}
              style={{ minWidth: 200, maxWidth: 400, height: 200 }}
            />
          )}
        </div>
        <Flex $gap={16}>
          <Button onClick={handleShowNextGif}>
            <Icon name="reload" size={20} />
          </Button>
          <Button onClick={removeGiphy}>
            <Icon name="close" size={20} />
          </Button>
          <Button onClick={handleAddComment} $htmlType="primary">
            <Icon name="save" size={20} color="#fff" />
          </Button>
        </Flex>
      </Flex>
      {children}
    </div>
  );
};
export default Sticker;
