import { Button } from 'src/components/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 10px;
`;

export const AttachmentsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 20px;
`;

export const AttachmentItem = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  display: flex;
  align-items: flex-start;
  position: relative;
  border: 1px solid ${p => p.theme.border.color.primary};
  background: ${p => p.theme.background.secondary};
`;

export const AttachmentItemImg = styled.div`
  width: 100px;
  height: 100px;
  min-width: 100px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.border.radius.sm};
  }
`;

export const AttachmentItemBody = styled.div`
  margin: 0 0 0 10px;
  position: relative;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 110px);
`;

export const AttachmentItemTitle = styled.div`
  margin-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const AttachmentItemCloseBtn = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const AttachmentItemInfo = styled.div`
  margin-top: auto;
`;

export const AttachmentItemInfoText = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OpenAttachmentsDropDown = styled(Button)`
  padding: 0;
  border: none;
`;

export const AddAttachmentsTitle = styled.h2`
  color: ${p => p.theme.text.active};
  margin: 0;
`;
