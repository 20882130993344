import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormItem, Button } from 'src/components/design-system';

import { useCustomFields } from '../../../hooks/useCustomFields';

type AddCustomFieldProps = {
  title: string;
  type: string;
};
const AddCustomField = () => {
  const { t } = useTranslation();
  const { onCreateCustomField } = useCustomFields();

  const methods = useForm<AddCustomFieldProps>({
    defaultValues: {
      type: 'text',
    },
  });

  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = ({ title, type }) => {
    onCreateCustomField({
      title,
      type,
      reset,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem label="Add custom field" name="title">
            <input type="text" placeholder="Enter title" />
          </FormItem>

          <FormItem label="Custom field type" name="type">
            <select
              onChange={e => {
                setValue('type', e.target.value);
              }}
            >
              <option value="text">
                {t('project.customField.customFieldText')}
              </option>
              <option value="date">
                {t('project.customField.customFieldDate')}
              </option>
            </select>
          </FormItem>

          <Button $htmlType="submit">
            {t('project.customField.createNewCustomField')}
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default AddCustomField;
