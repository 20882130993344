import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/design-system';

import { useBooks } from 'src/hooks/useBooks';

import Book from './Book/Book';
import UploadBookModal from './UploadBookModal/UploadBookModal';
import { openUploadBookModalVar } from 'src/graphql/vars';
import MainLayout from 'src/layouts/MainLayout/MainLayout';

import * as Styled from './styles';

const Books = () => {
  const { t } = useTranslation();
  const { books } = useBooks();

  return (
    <>
      <Helmet>
        <title>{t('books.title')}</title>
        <meta
          name="Books"
          content="Audio books, ebooks, and more. Read and listen anywhere, on any device."
        />
      </Helmet>
      <MainLayout>
        <Styled.Container>
          <h2>{t('books.title')}</h2>

          <Styled.UploadBookBlock>
            <Button
              $htmlType="primary"
              onClick={() => openUploadBookModalVar(true)}
            >
              {t('books.uploadBook')}
            </Button>
          </Styled.UploadBookBlock>

          <div>
            {books.map(book => (
              <Book key={book._id} book={book} />
            ))}
          </div>
        </Styled.Container>
      </MainLayout>
      <UploadBookModal />
    </>
  );
};

export default Books;
