import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import Board from './Board';
import BoardSkeleton from './BoardSkeleton';
import { GET_PROJECT_TASKS_FOR_BOARD_VIEW } from 'src/graphql/actions';
import { useProjects } from 'src/helpers/apollo';

const BoardContainer = () => {
  const { id } = useParams<{ id: string }>();
  const projects = useProjects();

  const columnCount =
    projects.find(project => project._id === id)?.boardsSortList.length || 3;

  const { loading, error, data } = useQuery(GET_PROJECT_TASKS_FOR_BOARD_VIEW, {
    variables: { projectId: id },
  });

  if (error) return <p>{`Error! ${error}`}</p>;

  if (loading) return <BoardSkeleton columnCount={columnCount} />;

  return <Board kanbanTasks={data.kanbanTasks} />;
};

export default BoardContainer;
