import React from 'react';

import TaskAssignee from '../Task/TaskAssignee/TaskAssignee';
import { useIsTaskCompleted } from 'src/helpers/task';

import { Event, TaskName } from './styles';

const CalendarEvent = ({ event }) => {
  const { id, title, extendedProps } = event;

  const { assignee } = extendedProps;

  const isTaskCompleted = useIsTaskCompleted(extendedProps);

  return (
    <Event $isCompleted={isTaskCompleted}>
      <TaskAssignee taskId={id} assignee={assignee} compact isCalendar />
      <TaskName>{title}</TaskName>
    </Event>
  );
};

export default React.memo(CalendarEvent);
