import React, { useCallback } from 'react';

import { Button, Flex } from 'src/components/design-system';

import * as Styled from './styles';

const DEFAULT_RATE_COUNT = 5;

type RatingProps = {
  isEditable?: boolean;
  value?: number;
  onChange?: (value: number) => void;
};
const Rating = ({ isEditable, value = 1, onChange }: RatingProps) => {
  const [rateCount, setRateCount] = React.useState(DEFAULT_RATE_COUNT);

  const handleIncreaseRating = useCallback(() => {
    setRateCount(rateCount + 1);
  }, [rateCount]);

  const handleDecreaseRating = useCallback(() => {
    setRateCount(rateCount - 1);
  }, [rateCount]);

  return (
    <Flex $padding={[40, 0]} $gap={20} $alignItems="center">
      {isEditable && (
        <Flex $gap={10}>
          <Button onClick={handleDecreaseRating}>-</Button>
          <Button onClick={handleIncreaseRating}>+</Button>
        </Flex>
      )}

      <Flex $gap={10}>
        {Array.from({ length: rateCount }, (_, index) => (
          <Styled.Rating
            $active={value === index + 1}
            $alignItems="center"
            $justifyContent="center"
            key={index}
            onClick={() => {
              if (onChange) {
                onChange(index + 1);
              }
            }}
          >
            {index + 1}
          </Styled.Rating>
        ))}
      </Flex>
    </Flex>
  );
};

export default Rating;
