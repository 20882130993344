import React from 'react';

import { Button } from 'src/components/design-system';

import { useComments } from 'src/hooks/useComments';

import PaginatedStickers from './PaginatedStickers/PaginatedStickers';
import Sticker from './Sticker/Sticker';
import { stickers, stickerBucked } from './constants';
import Dropdown from 'rc-dropdown';

const Stickers = ({ taskId }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const { onCreateComment } = useComments();

  return (
    <>
      {stickers.map(
        ({ stickersName, count, previewNumber, type, extension }) => {
          const previewUrl = `${stickerBucked}${stickersName}/${previewNumber}${extension}`;

          return (
            <Dropdown
              key={stickersName}
              placement="topRight"
              trigger={['click']}
              onVisibleChange={visible => {
                setIsVisible(visible);
              }}
              overlay={() => (
                <>
                  {isVisible && (
                    <PaginatedStickers
                      taskId={taskId}
                      stickersName={stickersName}
                      count={count}
                      onCreateComment={onCreateComment}
                      isVisible={isVisible}
                      type={type}
                      extension={extension}
                    />
                  )}
                </>
              )}
              getPopupContainer={(trigger: HTMLElement) =>
                trigger?.parentNode as HTMLElement
              }
            >
              <Button $htmlType="text" size="small">
                <Sticker
                  type={type}
                  url={previewUrl}
                  stickersName={stickersName}
                  size={20}
                />
              </Button>
            </Dropdown>
          );
        },
      )}
    </>
  );
};

export default Stickers;
