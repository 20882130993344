import styled from 'styled-components';

export const TableHeaderCell = styled.th`
  padding: 12px;
  text-align: left;
  &:first-child {
    border-top-left-radius: 12px;
  }
  &:last-child {
    border-top-right-radius: 12px;
  }
`;
