import React from 'react';
import { useParams } from 'react-router-dom';

import { Switch } from 'src/components/design-system';

import { useCustomFields } from 'src/hooks/useCustomFields';

import AddCustomField from './AddCustomField';

import * as Styles from './styles';

const CustomFields = () => {
  const { id: projectId } = useParams() as any;

  const { customFields, onToggleCustomFieldToProject } = useCustomFields();

  return (
    <Styles.Container>
      <AddCustomField />

      <div>
        {customFields.map(({ _id, title, projectIds }) => (
          <Styles.Field key={_id}>
            <div>{title}</div>
            <div>
              <Switch
                id="123"
                isChecked={projectIds.includes(projectId)}
                onChange={isChecked => {
                  onToggleCustomFieldToProject({
                    customFieldId: _id,
                    isChecked,
                  });
                }}
              />
            </div>
          </Styles.Field>
        ))}
      </div>
    </Styles.Container>
  );
};

export default CustomFields;
