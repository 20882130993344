import { Drawer } from 'src/components/design-system';
import NotificationsList from './NotificationsList';
import { DRAWER_IDS, useDrawerStore } from 'src/store';

const Notifications = () => {
  const { isOpen, closeDrawer } = useDrawerStore(DRAWER_IDS.NOTIFICATIONS);

  if (!isOpen) return null;

  return (
    <Drawer
      open={isOpen}
      width={450}
      showCloseIcon={false}
      mask={false}
      onClose={closeDrawer}
    >
      <NotificationsList />
    </Drawer>
  );
};

export default Notifications;
