import React, { useEffect, useState } from 'react';

import { Button, Flex, Icon } from 'src/components/design-system';

const Volume = ({ audioRef }) => {
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  return (
    <Flex $alignItems="center" $gap={8}>
      <Button
        size="small"
        $htmlType="text"
        onClick={() => setMuteVolume(prev => !prev)}
      >
        {muteVolume || volume < 5 ? (
          <Icon name="volume-off" size={20} />
        ) : volume < 40 ? (
          <Icon name="volume-small" size={20} />
        ) : (
          <Icon name="volume-high" size={20} />
        )}
      </Button>
      <input
        type="range"
        min={0}
        max={100}
        value={volume}
        onChange={e => setVolume(e.target.value as any)}
        style={{
          background: `linear-gradient(to right, #f50 ${volume}%, #ccc ${volume}%)`,
        }}
      />
    </Flex>
  );
};

export default Volume;
