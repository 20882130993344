import { gql } from '@apollo/client';

gql`
  query Users($workspaceId: ID!, $projectId: ID) {
    users(workspaceId: $workspaceId, projectId: $projectId) {
      _id
      firstName
      lastName
      fullName
      email
      profilePicture
    }
  }
`;

gql`
  query DashboardTasksByPeriod(
    $workspaceId: ID!
    $period: DashboardTaskPeriodEnum
    $projectId: ID
  ) {
    dashboardTasksByPeriod(
      workspaceId: $workspaceId
      period: $period
      projectId: $projectId
    ) {
      day
      count
      tasks {
        _id
        title
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      _id
      workspaceId
      firstName
      lastName
      fullName
      role
      email
      profilePicture
      pushNotificationToken
      timeTracking {
        _id
        startTime
        endTime
        totalTime
        status
        actionId {
          _id
          title
        }
      }
      listening {
        chapterId
        listenedTime
      }
      minimizedTasks {
        _id
        title
      }
    }
  }
`;
