import React, { useMemo } from 'react';

import { Button, Icon, Menu, useConfirm } from 'src/components/design-system';

import { useStatuses } from 'src/hooks/useStatuses';

import { useProject } from 'src/helpers/apollo';

import { columnMoreMenu, COLUMN_MORE_MENU } from './constants';
import Dropdown from 'rc-dropdown';
import { editStatusVar } from 'src/graphql/vars';

const ColumnMoreMenu = ({ column }) => {
  const { onDeleteStatus } = useStatuses();
  const { project } = useProject();
  const confirm = useConfirm();

  const { completedStatusId, unstagedStatusId } = project;

  const handleMenuClick = async (id: string) => {
    switch (id) {
      case COLUMN_MORE_MENU.EDIT:
        editStatusVar({
          _id: column._id,
          title: column.title,
          color: column.color,
        });
        break;
      case COLUMN_MORE_MENU.DELETE:
        confirm({
          message:
            'If you delete this status, all tasks in this status will be deleted. Are you sure?',
        })
          .then(() => {
            onDeleteStatus({ statusId: column._id });
          })
          .catch();

        break;
      default:
        break;
    }
  };

  const columnOptions = useMemo(() => {
    if (column._id === completedStatusId || column._id === unstagedStatusId) {
      return columnMoreMenu.filter(item => item.id !== COLUMN_MORE_MENU.DELETE);
    }
    return columnMoreMenu;
  }, [column._id, completedStatusId, unstagedStatusId]);

  return (
    <Dropdown
      trigger={['click']}
      overlay={() => (
        <Menu options={columnOptions} handleMenuClick={handleMenuClick} />
      )}
      getPopupContainer={(trigger: HTMLElement) =>
        trigger?.parentNode as HTMLElement
      }
    >
      <div>
        <Button size="small" $htmlType="text">
          <Icon name="dots-vertical" />
        </Button>
      </div>
    </Dropdown>
  );
};

export default ColumnMoreMenu;
