import { useTransition, animated } from '@react-spring/web';
import React, { useRef } from 'react';

import { Button, Icon, Portal } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import useOnClickOutside from 'src/hooks/common/useOnClickOutside';

import {
  Container,
  DrawerMask,
  ContentWrapper,
  Content,
  CloseButton,
} from './styles';

// https://github.com/react-component/drawer
type DrawerProps = {
  open?: boolean;
  inline?: boolean;
  forceRender?: boolean;
  autoFocus?: boolean;
  keyboard?: boolean;

  // Root
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
  zIndex?: number;

  // Drawer
  placement?: string; // Placement;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  width?: number | string;
  height?: number | string;
  contentWrapperStyle?: React.CSSProperties;
  showCloseIcon?: boolean;

  // Mask
  mask?: boolean;
  maskClosable?: boolean;
  maskClassName?: string;
  maskStyle?: React.CSSProperties;

  // Events
  afterOpenChange?: (open: boolean) => void;
  onClose?: (
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => void;
  destroyOnClose?: boolean;
};

const Drawer = ({
  open = false,
  // placement = 'right',
  // autoFocus = true,
  // keyboard = true,
  width = 378,
  mask = true,
  maskClosable = true,
  // forceRender,
  // afterOpenChange,
  // destroyOnClose,
  children,
  onClose,
  showCloseIcon = true,
}: DrawerProps) => {
  const ref = useRef(null);
  const theme = useTheme();

  const panelNodeTransition = useTransition(open, {
    from: { right: -width },
    enter: { right: 0 },
    leave: { right: -width },
  });

  const maskNodeTransition = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const closeButtonNode = showCloseIcon && (
    <CloseButton>
      <Button
        size="little"
        $htmlType="text"
        onClick={onClose}
        icon={<Icon name="close" size={24} color={theme.text.primary} />}
      />
    </CloseButton>
  );

  // ============================ Mask ============================
  const maskNode: React.ReactNode = mask && (
    <>
      {maskNodeTransition(
        (style, item) =>
          item && (
            <DrawerMask
              style={style}
              as={animated.div as any}
              onClick={maskClosable && open ? onClose : undefined}
            />
          ),
      )}
    </>
  );

  // =========================== Panel ============================
  const panelNode: React.ReactNode = (
    <>
      {panelNodeTransition(
        (style, item) =>
          item && (
            <ContentWrapper
              as={animated.div as any}
              ref={ref}
              style={style}
              width={width}
            >
              <Content>
                {closeButtonNode}
                {children}
              </Content>
            </ContentWrapper>
          ),
      )}
    </>
  );

  useOnClickOutside(ref, () => {
    onClose && onClose();
  });

  return (
    <Portal>
      <Container>
        {maskNode}
        {panelNode}
      </Container>
    </Portal>
  );
};

export default Drawer;
