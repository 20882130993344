import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  overflow-x: auto;
`;

type TableProps = {
  loading?: boolean;
  width?: number;
};

export const Table = styled.table<TableProps>`
  border-collapse: collapse;
  min-width: 100%;
  width: ${({ width }) => width}px;
  border: none;
  margin-top: 10px;

  thead {
    border-top: 1px solid ${({ theme }) => theme.border.color.primary};
    border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.background.secondary};
  }
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
`;
export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
