import styled from 'styled-components';

import { objectFitImg } from '../mixins';

type AvatarProps = {
  size?: number;
  $mr?: number;
  isAvatar?: boolean;
  radius?: number;
};

export const Container = styled.div<AvatarProps>`
  width: ${({ size }) => size || 32}px;
  height: ${({ size }) => size || 32}px;
  min-width: ${({ size }) => size || 32}px;
  margin-right: ${({ $mr }) => $mr}px;
  font-size: ${({ size }) => (size ? Math.floor(size / 2.8) : 14)}px;
  border-radius: 50%;
`;

export const ImageBlock = styled(Container)<AvatarProps>`
  img {
    ${objectFitImg('cover')}
    border-radius: ${({ radius }) => (radius ? `${radius}px` : '50%')};
  }
`;

export const PreviewBlock = styled(Container)<AvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 2px;
  font-size: ${({ size }) => (size ? Math.floor(size / 2.8) : 14)}px;
  color: ${({ theme }) => theme.color.primary};
  box-shadow: ${({ theme }) => theme.boxShadow.sm};
  background-color: ${({ theme }) => theme.background.primary};
  border: 1px solid ${({ theme }) => theme.border.color.strong};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '50%')};
`;
