import styled, { css } from 'styled-components';

import Flex from '../Flex/Flex';

type AvatarListProps = {
  $allowInvite?: boolean;
};

export const Container = styled(Flex)<AvatarListProps>`
  border-radius: ${({ theme }) => theme.border.radius.sm};

  ${({ $allowInvite }) =>
    $allowInvite &&
    css`
      cursor: pointer;
    `}
  &:hover {
    // border-color: ${({ theme }) => theme.border.color.primary};
    background: ${({ theme }) => theme.background.hover};
  }
`;
export const User = styled.div`
  &:not(:first-child) {
    margin-left: -10px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.border.color.secondary};
  }
`;

export const AddUser = styled.div`
  background: ${({ theme }) => theme.background.secondary};
  border: 1px solid ${({ theme }) => theme.border.color.secondary};
  margin-left: -10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
`;
