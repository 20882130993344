import { flexRender, Cell } from '@tanstack/react-table';
import React, { CSSProperties, useMemo, memo } from 'react';
import { PRIORITY } from 'src/common/constants';
import { Skeleton } from 'src/components/design-system';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TableTask } from '../types';

import * as Styled from './styles';

type TableCellProps = {
  cell: Cell<TableTask, unknown>;
  setEditingCell: (value: string | null) => void;
  editingCell: string | null;
  isRowSelected: boolean;
  isCompleted: boolean;
  priority: PRIORITY;
};

const NO_PADDING_COLUMN = ['statusId', 'priority', 'storyPoints'];

const TableCell = ({
  cell,
  setEditingCell,
  editingCell,
  isRowSelected,
  isCompleted,
  priority,
}: TableCellProps) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style: CSSProperties = useMemo(() => {
    return {
      transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
      transition: 'width transform 0.2s ease-in-out',
      width: cell.column.getSize(),
      zIndex: isDragging || editingCell === cell.id ? 1 : 0,
      position: isDragging || editingCell === cell.id ? 'relative' : 'static',
    };
  }, [transform, cell.column, cell.id, isDragging, editingCell]);

  if (isDragging)
    return (
      <Styled.Cell
        $padding={[10, 20]}
        $completed={isCompleted}
        $selected={isRowSelected}
        $priority={priority}
        $alignItems="center"
        style={{
          ...style,
          width: cell.column.getSize(),
        }}
      >
        <Skeleton />
      </Styled.Cell>
    );

  return (
    <Styled.Cell
      $completed={isCompleted}
      $selected={isRowSelected}
      $priority={priority}
      $alignItems="center"
      ref={setNodeRef}
      style={{
        ...style,
        width: cell.column.getSize(),
      }}
      $noPadding={NO_PADDING_COLUMN.includes(cell.column.id)}
      onClick={e => {
        e.stopPropagation();
        setEditingCell(editingCell === cell.id ? null : cell.id);
      }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </Styled.Cell>
  );
};
export default memo(TableCell);
