import styled, { css } from 'styled-components';

type CardProps = {
  $active?: boolean;
};

export const FormCardSettings = styled.div<CardProps>`
  opacity: 0;
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;

  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
    `}
`;

export const Card = styled.div<CardProps>`
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px 0 #00000040;
  padding: 20px 20px 60px 20px;
  background: ${({ theme }) => theme.background.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  border: 2px solid ${({ theme }) => theme.color.white};

  input,
  textarea {
    margin-top: 10px;
    padding: 8px 14px;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    width: 100%;
    border: 1px solid ${p => p.theme.border.color.primary};
    background-color: ${p => p.theme.background.primary};
    outline: none;
    box-shadow: rgb(59 148 191 / 30%) 0 0 0 4px;
    cursor: auto;
  }
  textarea {
    min-height: 100px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.color.green400};

    ${FormCardSettings} {
      opacity: 1;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      border-color: ${({ theme }) => theme.color.success};
      &:hover {
        border-color: ${({ theme }) => theme.color.success};
      }
    `}
`;

export const Sup = styled.sup`
  color: ${({ theme }) => theme.color.danger};
  margin-left: 5px;
`;
