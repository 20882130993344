import { Flex } from 'src/components/design-system';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
`;

type RatingProps = {
  $active?: boolean;
};

export const Rating = styled(Flex)<RatingProps>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  ${({ $active }) =>
    $active &&
    css`
      border: 2px solid ${({ theme }) => theme.color.success};
    `}
`;
