import { Flex, objectFitImg } from 'src/components/design-system';
import styled, { css } from 'styled-components';

type SubtaskContainerProps = {
  $isDragging?: boolean;
  $isTaskCompleted?: boolean;
};

export const ReorderIcon = styled.div`
  opacity: 0;
  visibility: hidden;
  cursor: grab;
`;

export const Container = styled(Flex)<SubtaskContainerProps>`
  width: 100%;
  padding: 5px 0;
  &:hover {
    background: ${({ theme }) => theme.background.secondary};
    ${ReorderIcon} {
      opacity: 1;
      visibility: visible;
    }
  }
  ${({ $isDragging }) =>
    $isDragging &&
    css`
      background: ${({ theme }) => theme.background.secondary};
      ${ReorderIcon} {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

export const Subtask = styled(Flex)`
  height: 45px;
  padding: 10px;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.background.hover};
  }
`;

export const Title = styled.div<SubtaskContainerProps>`
  ${({ $isTaskCompleted }) =>
    $isTaskCompleted &&
    css`
      text-decoration: line-through;
      color: ${({ theme }) => theme.text.secondary};
    `}
`;

export const AddSubtaskInput = styled.input`
  padding: 10px;
  cursor: pointer;
  margin: 5px 0 0;
  background: ${({ theme }) => theme.background.primary};
  border: none;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  width: 100%;
  color: ${({ theme }) => theme.text.primary};
  &:focus {
    outline: none;
  }
`;

export const Attachment = styled.div`
  width: 30px;
  height: 30px;
  img {
    border-radius: ${({ theme }) => theme.border.radius.sm};
    ${objectFitImg('cover')}
  }
`;
