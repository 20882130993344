import React from 'react';
import { OptionProps } from 'react-select';

import { Flex } from 'src/components/design-system';

import Option from '../Option/Option';

import { StoryPointCount, StoryPointText } from './styles';

const StoryPointsOption = ({
  innerProps,
  innerRef,
  data,
  isFocused,
  isSelected,
}: OptionProps) => {
  // @ts-ignore
  const { label, value } = data;

  return (
    <div ref={innerRef} {...innerProps}>
      <Option isFocused={isFocused} isSelected={isSelected}>
        <Flex $alignItems="center" $padding={10}>
          <StoryPointCount>{value}</StoryPointCount>
          <StoryPointText>{label}</StoryPointText>
        </Flex>
      </Option>
    </div>
  );
};

export default StoryPointsOption;
