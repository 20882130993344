import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  FormItem,
  Modal,
  Icon,
} from 'src/components/design-system';

import { useCanvasBoards } from 'src/hooks/useCanvasBoards';

import { useUserWorkspace } from 'src/store';

import * as Styled from './styles';

const CreateMiroBoard = () => {
  const { t } = useTranslation();
  const [openCreateMiroBoardModal, setOpenCreateMiroBoardModal] =
    useState(false);

  const workspaceId = useUserWorkspace();
  const { onCreateCanvasBoard } = useCanvasBoards({
    workspaceId,
  });

  const methods = useForm<{ title: string }>();

  const { handleSubmit, reset } = methods;

  const handleCreateProject = async ({ title }) => {
    try {
      await onCreateCanvasBoard({ workspaceId, title });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = () => {
    setOpenCreateMiroBoardModal(false);
    reset();
  };

  return (
    <>
      <Styled.CreateBoard
        $alignItems="center"
        $justifyContent="center"
        onClick={() => setOpenCreateMiroBoardModal(true)}
      >
        <Icon name="plus" color="#fff" size={30} />
      </Styled.CreateBoard>
      <Modal
        width={500}
        isOpen={openCreateMiroBoardModal}
        onRequestClose={onClose}
      >
        <Styled.Title>{t('miro.createNewBoard.title')}</Styled.Title>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleCreateProject)}>
            <FormItem name="title" label={t('miro.createNewBoard.inputTitle')}>
              <input
                type="text"
                placeholder={t('miro.createNewBoard.inputTitlePlaceholder')}
              />
            </FormItem>

            <Flex $gap={10} $justifyContent="flex-end">
              <Button onClick={onClose}>{t('general.close')}</Button>
              <Button
                loading={methods.formState.isSubmitting}
                onClick={handleSubmit(handleCreateProject)}
                $htmlType="primary"
              >
                {t('miro.createNewBoard.createBoard')}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CreateMiroBoard;
