import React from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Tag, Select } from 'src/components/design-system';

import { useProjects } from 'src/hooks/useProjects';

import { useProject } from 'src/helpers/apollo';

import { ProjectStatusEnum } from 'src/generated';

import * as Styled from './styles';

const ProjectStatus = () => {
  const { project } = useProject();
  const { onUpdateProject } = useProjects();
  const { id: projectId } = useParams() as any;

  const { status } = project;

  const statuses = [
    {
      value: ProjectStatusEnum.OnTrack,
      label: 'On track',
      color: '#58a182',
    },
    {
      value: ProjectStatusEnum.AtRisk,
      label: 'At risk',
      color: '#f1bd6c',
    },
    {
      value: ProjectStatusEnum.OffTrack,
      label: 'Off track',
      color: '#de5f73',
    },
    {
      value: ProjectStatusEnum.OnHold,
      label: 'On hold',
      color: '#d1aeae',
    },
    {
      value: ProjectStatusEnum.Archived,
      label: 'Archived',
      color: '#d8cccc',
    },
    {
      value: ProjectStatusEnum.Completed,
      label: 'Completed',
      color: '#58a182',
    },
  ];

  if (!status) {
    return (
      <div>
        <h2>{"What's the status?"}</h2>
        <Flex $gap={10} $flexWrap="wrap">
          {statuses.map(({ value, label, color }) => (
            <Tag
              key={value}
              color={color}
              onClick={() => onUpdateProject({ projectId, status: value })}
            >
              {label}
            </Tag>
          ))}
        </Flex>
      </div>
    );
  }

  const currentStatus = statuses.find(({ value }) => value === status);

  if (!currentStatus) {
    return null;
  }

  const { color, label } = currentStatus;

  return (
    <Flex $justifyContent="space-between" margin={[0, 0, 12, 0]}>
      <Styled.ProjectStatusTitle style={{ color }}>
        {label}
      </Styled.ProjectStatusTitle>
      <Select
        serachable={false}
        defaultValue={currentStatus}
        options={statuses}
        onChange={({ value }) => {
          onUpdateProject({ projectId, status: value });
        }}
      />
    </Flex>
  );
};

export default ProjectStatus;
